import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Header from "./Header";
import Body from "./Body";
import {
  getLocalStorageData,
  removeLocalStorageData,
} from "../../utils/localStorageHelper";
import { ADS_OBSERVABILITY, USER_DATA_KEY } from "../../Constant";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { getCurrentTime } from "../../utils/helper";

function Content(props) {
  const [client, setClient] = useState(null);
  const [userType, setUserType] = useState({});

  useEffect(() => {
    const getLocalUserData = getLocalStorageData(USER_DATA_KEY, true);
    const getCurrentEpochTime = getCurrentTime();

    if (
      getLocalUserData?.expires_on &&
      getLocalUserData.expires_on > getCurrentEpochTime
    ) {
      setUserType(getLocalUserData);
    } else {
      removeLocalStorageData(USER_DATA_KEY);
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    const httpLink = createHttpLink({
      uri: ADS_OBSERVABILITY,
    });

    const _client = new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
    });
    setClient(_client);
  }, []);

  if (!client) return null;

  return (
    <ApolloProvider client={client}>
      <Grid container className="amagi-content-div">
        <Grid item xs={12} className="amagi-content-header-div">
          <Header userData={userType}/>
        </Grid>
        <Grid item xs={12} className="amagi-content-body-div">
          <Body userData={userType}/>
        </Grid>
      </Grid>
    </ApolloProvider>
  );
}

export default Content;
