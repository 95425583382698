import { gql, useMutation } from '@apollo/client';

const UPDATE_USER_ROLE = gql`
  mutation MyMutation($updateUserData: UserUpdate = {}) {
  updateUser(updateUserData: $updateUserData)
    }
`;

const useUpdateUserRole = () => {
    const [updateUser, { loading, error }] = useMutation(UPDATE_USER_ROLE);

    const handleUpdateUserRole = async (updateUserData) => {
        try {
            console.log("DATA>>", updateUserData)
            await updateUser({
                variables: updateUserData

            });
            console.log('User role updated successfully!');
        } catch (mutationError) {
            console.error('Error updating user role:', mutationError);
        }
    };

    return { handleUpdateUserRole, loading, error };
};

export default useUpdateUserRole;
