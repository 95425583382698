import React, { useEffect, useState } from "react"
import {
    Button,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemText, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    isEmpty,
    convertEpochToDate,
    copyToClipBoard,
    removeString, getKeyType, formatedJSON, convertUTCTODate
} from "../../../../../utils/helper";
import {
    JOB_RUN_MAPPING,
    FORMATED_COMMA_KEY,
    ISSUE_DETAIL_ORDER,
    DISABLE_FEATURE_MSG,
    AUTO_HIDE_DURATION_TIME
} from "../../../../../Constant";
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { formatValue } from "../../../../../utils/helper";


function DetailsContent(props) {
    const [detailsData, setDetailsData] = useState({})
    const [open, setOpen] = useState(false)
    const [notifiedMessage, setNotifiedMessage] = useState("")
    const [metricsData, setMetricsData] = useState([])
    const [actionItems, setActionItems] = useState([])
    const [isInternal, setIsInternal] = useState(false);

    useEffect(() => {
        if (props?.isInternal) {
            setIsInternal(true);
        }
    }, [props?.isInternal]);

    useEffect(() => {

        if (!isEmpty(props.data)) {
            const getMetricsValue = props.data?.["metrics"] && props.data["metrics"] !== "" ? JSON.parse(props.data["metrics"]) : {}
            let mapMetricsKey = {}

            Object.keys(getMetricsValue).map((key) => {
                const getRemoveString = removeString(key)
                const getKeyValue = getKeyType(key)

                if (mapMetricsKey[getRemoveString]) {
                    mapMetricsKey[getRemoveString][getKeyValue] = getRemoveString === "Time" ? convertUTCTODate(getMetricsValue[key]) : getMetricsValue[key]
                } else {
                    mapMetricsKey[getRemoveString] = {}
                    mapMetricsKey[getRemoveString][getKeyValue] = getRemoveString === "Time" ? convertUTCTODate(getMetricsValue[key]) : getMetricsValue[key]
                }

                return true
            })
            const getActionItems = props.data?.["rule_details"]?.["NextSteps"] && props.data["rule_details"]["NextSteps"] !== "" ? props.data["rule_details"]["NextSteps"].split("*") : []


            setMetricsData(mapMetricsKey)
            setActionItems(getActionItems)
            setDetailsData(props.data)
        }


    }, [props?.data])

    const onClickCopy = async (val) => {
        if (val) {
            const data = await copyToClipBoard(val)

            if (data) {
                setNotifiedMessage("Text Copied")
                setOpen(true)
            }
        }
    }

    const onClickJson = async () => {
        const formatedData = formatedJSON({ ...detailsData, metricsData: metricsData, actionItems: actionItems })
        const getValue = JSON.stringify(formatedData)
        if (getValue) {
            const data = await copyToClipBoard(getValue)
            if (data) {
                setNotifiedMessage("Text Copied")
                setOpen(true)
            }
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onClickSendEmail = () => {
        setNotifiedMessage(DISABLE_FEATURE_MSG)
        setOpen(true)

    }
    const issue_detail_table_cell = (key, valueType, metricsData) => {
        return FORMATED_COMMA_KEY.includes(key)
            ? formatValue(metricsData[key]?.[valueType])
            : metricsData[key]?.[valueType]
                ? metricsData[key][valueType]
                : 'N/A';
    };

    return (
        <Drawer open={props.openDrawer} onClose={props.onCloseDrawer} anchor="right" className="details-drawer-content">
            <Grid container>
                <Grid item xs={12} className="details-header-drawer">
                    <Grid container>
                        <Grid item xs={10}>
                            Alert Details
                        </Grid>
                        <Grid item xs={2} className="text-align-left">
                            <CloseIcon onClick={props.onCloseDrawer} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="details-content-drawer">
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Time of issue &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            {convertEpochToDate(detailsData?.epoch)}
                        </Grid>
                    </Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Alert name &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            {detailsData?.rule_details?.Title} &nbsp; <ContentCopyIcon className="copy-icon" onClick={() => onClickCopy(detailsData?.rule_details?.Title)} />
                        </Grid>
                    </Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Alert type &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            {detailsData?.validation_type}
                        </Grid>
                    </Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Alert category &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            Monetization
                        </Grid>
                    </Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details ">
                            Channel name &nbsp; :
                        </Grid>
                        <Grid item xs={9} className="">
                            {detailsData?.channel_name !== "ChannelMappingNotFound" ? detailsData?.channel_name : "N/A"} &nbsp; <ContentCopyIcon className="copy-icon" onClick={() => onClickCopy(detailsData?.channel_name)} />
                        </Grid>
                    </Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Ad tag id(s) &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            <List className="list-details-demand-partner">
                                {
                                    detailsData?.details?.demandPartners && !isEmpty(detailsData.details.demandPartners) ? detailsData.details.demandPartners.map((demandData) => {
                                        return (
                                            <ListItem key={demandData}>
                                                <ListItemText> {demandData}  <ContentCopyIcon className="copy-icon" onClick={() => onClickCopy(demandData)} /> </ListItemText>
                                            </ListItem>
                                        )
                                    }) : (
                                        <ListItem>
                                            <ListItemText> N/A </ListItemText>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="white-lined"></Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Metric calculation &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            {
                                detailsData?.rule_details?.Formula || "N/A"
                            }
                        </Grid>
                    </Grid>
                    {
                        detailsData?.details?.metricDeviation ? (
                            <Grid container className="details-content-row">
                                <Grid item xs={3} className="details-key-details">
                                    Metric deviation &nbsp; :
                                </Grid>
                                <Grid item xs={9}>
                                    {
                                        detailsData?.details?.metricDeviation
                                    }
                                </Grid>
                            </Grid>
                        ) : null
                    }
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Issue detail &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            <Table className="white-table-details">
                                <TableHead>
                                    <TableCell>PARAMETER</TableCell>
                                    <TableCell>CURRENT VALUE</TableCell>
                                    <TableCell>PREVIOUS VALUE</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        !isEmpty(metricsData) ? ISSUE_DETAIL_ORDER.map((key) => {
                                            return (
                                                metricsData[key] && (
                                                    <TableRow key={key}>
                                                        <TableCell>{key}</TableCell>
                                                        <TableCell>{issue_detail_table_cell(key, 'current_value', metricsData)}{key === "MetricRate" && "%"}</TableCell>
                                                        <TableCell>{issue_detail_table_cell(key, 'past_value', metricsData)}{key === "MetricRate" && "%"}</TableCell>
                                                    </TableRow>
                                                )

                                            );
                                        }) : (
                                            <TableRow>
                                                <TableCell> N/A </TableCell>
                                                <TableCell> N/A </TableCell>
                                                <TableCell> N/A </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>

                            </Table>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="white-lined"></Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Possible next steps &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            <List className="details-list-item">
                                {
                                    actionItems.length > 0 ? actionItems.map((actionText, index) => {
                                        if (index !== 0) {
                                            return (
                                                <ListItem>
                                                    <ListItemText>{index}. {actionText}</ListItemText>
                                                </ListItem>
                                            )
                                        }

                                        return true
                                    }) : "N/A"
                                }
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Last run date/time &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            {
                                convertEpochToDate(detailsData?.last_successful_epoch) || "N/A"
                            }
                        </Grid>
                    </Grid>
                    <Grid container className="details-content-row">
                        <Grid item xs={3} className="details-key-details">
                            Job run frequency &nbsp; :
                        </Grid>
                        <Grid item xs={9}>
                            {
                                JOB_RUN_MAPPING[detailsData?.aggregation_level] || "N/A"
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="details-footer-drawer">
                    <Grid conteainer>
                        <Grid item xs={12} className="text-align-left">
                            <Button variant="outlined" size="small" className="black-filled-button" startIcon={<ContentCopyIcon />} onClick={() => onClickJson()}> Copy To Clipboard </Button>
                            {!isInternal && (<React.Fragment>
                                <Button variant="outlined" size="small" className="black-filled-button" startIcon={<MailOutlineIcon />} onClick={() => onClickSendEmail()} disabled> Send Email </Button>
                            </React.Fragment>)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={AUTO_HIDE_DURATION_TIME}
                onClose={handleClose}
                message={notifiedMessage}
            />
        </Drawer>
    )
}

export default DetailsContent