import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import { MenuItem, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";

import { useAccess } from "../../../contexts/AccessContext";
import { useDeleteConfirm } from "../../../contexts/DeleteUserConfirmContext";
import { DeleteUserRole } from "../../../query/controlPlane/deleteUserRole";
import { useGetAuthToken } from "../../../query/controlPlane/getAuthToken";
import { useGetUserId } from "../../../query/controlPlane/getUserId";
//import GetUserByIdComponent from "../../../query/controlPlane/getUserId";
import { UpdateUserRole } from "../../../query/controlPlane/updateUserRole";
import { useDeleteUser } from "../../../query/deleteUser";
import { useGetUsersData } from "../../../query/getUser";
import useUpdateUserRole from "../../../query/updateUser";
import UserNotification from "../UserNotification";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DeleteUpdatePopover(props) {
  const { AuthTokenData } = useGetAuthToken();
  const { handleUpdateRole } = UpdateUserRole();
  const { accessData } = useAccess();
  const { handleDeleteUserRole } = DeleteUserRole();
  const { usersRefetch } = useGetUsersData();
  const { handleDeleteUser } = useDeleteUser({})
  const { handleUpdateUserRole } = useUpdateUserRole()
  const { openDeletePop, handleClickClose, heading, message, btnName, userEmail, userRole } =
    useDeleteConfirm();
  const [showNotification, setShowNotification] = useState(false);
  const [newRole, setNewRole] = useState("")
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const { data, error, loading, userIdRefetch } = useGetUserId({})

  const onCloseNotification = () => {
    setShowNotification(false);
  };

  const onOpenNotification = () => {
    setShowNotification(true);
  };

  const onClickOperation = async () => {
    try {
      if (btnName === "Update") {
        const userIdData = {
          productID: accessData?.controlplane_metadata?.productID,
          token: AuthTokenData.getAuthToken.access_token,
          userEmail: userEmail
        };
        await userIdRefetch({ "data": { ...userIdData } });
        const auth0User = data.getUserId.find(
          (user) => user.user_id.startsWith('auth0')
        );
        if (auth0User.user_id) {
          const deleteresponse = await handleDeleteUserRole({
            DeleteUserRoleData: {
              productID: accessData?.controlplane_metadata?.productID,
              userEmail: userEmail,
              token: AuthTokenData.getAuthToken.access_token,
              role: userRole,
              org_id: accessData?.org_id,
              user_id: auth0User.user_id
            }
          })
          if (deleteresponse) {
            const updateresponse = await handleUpdateRole({
              updateUserRoleData: {
                productID: accessData?.controlplane_metadata?.productID,
                userEmail: userEmail,
                token: AuthTokenData.getAuthToken.access_token,
                role: newRole,
                org_id: accessData?.org_id,
                user_id: auth0User.user_id
              }
            })
            if (updateresponse) {
              await handleUpdateUserRole(
                {
                  updateUserData: {
                    user_id: userEmail,
                    newRole: newRole
                  }
                })
              usersRefetch();
            }
          }

          setSnackbarMessage(`User role has been updated to ${newRole}`)
        }
      }
      else {
        const userIdData = {
          productID: accessData?.controlplane_metadata?.productID,
          token: AuthTokenData.getAuthToken.access_token,
          userEmail: userEmail
        };
        await userIdRefetch({ "data": { ...userIdData } });
        const auth0User = data.getUserId.find(
          (user) => user.user_id.startsWith('auth0')
        );
        if (auth0User.user_id) {
          const deleteresponse = await handleDeleteUserRole({
            DeleteUserRoleData: {
              productID: accessData?.controlplane_metadata?.productID,
              userEmail: userEmail,
              token: AuthTokenData.getAuthToken.access_token,
              role: userRole,
              org_id: accessData?.org_id,
              user_id: auth0User.user_id
            }
          })
          if (deleteresponse) {
            const deleteUserResponse = await handleDeleteUser({
              userId: userEmail
            })
            if (deleteUserResponse) {
              usersRefetch();
            }
          }
          setSnackbarMessage(`User account has been deleted`)
        }
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <BootstrapDialog
        className="del-dialog"
        aria-labelledby="customized-dialog-title"
        open={openDeletePop}
        onClose={handleClickClose}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {heading}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClickClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="dialog-divider">
          <p style={{ color: "#868686" }}>{message}</p>

          {heading === "Update Permission" && (
            <Grid>
              <TextField
                select
                className="text-field-add-user"
                label="Role"
                id="outlined-required"
                variant="standard"
                size="medium"
                fullWidth
                margin="normal"
                value={newRole}
                onChange={(e) => setNewRole(e.target.value)}
                required
              >
                <MenuItem key="admin" className="role-dropdown" value="rol_HM3zwrpqi0npPNAQ">Admin</MenuItem>
                <MenuItem key="user" className="role-dropdown" value="rol_wXO5CcdjvkeIa142">User</MenuItem>
              </TextField>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              className="cancel-button-del"
              style={{ marginRight: "10px" }}
              onClick={handleClickClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="create-button-del"
              style={{ marginRight: "15px" }}
              onClick={() => {
                onClickOperation();
                handleClickClose();
                onOpenNotification();
              }}
            >
              {btnName}
            </Button>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
      {showNotification && (
        <UserNotification
          open={showNotification}
          handleClose={onCloseNotification}
          message={snackbarMessage}
        />
      )}
    </div>
  );
}
