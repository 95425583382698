import React, {useEffect, useRef, useState} from "react";
import GenericDropdown from "../base/GenericDropdown";
import Typography from "@mui/material/Typography";
import {isEmpty} from "../../utils/helper"

function AlertNameContent(props) {
    const [_alertNames, _setAlertNames] = useState([]);
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        _setAlertNames(
            props.alertNames.map((option) => ({ value: option, label: option }))
        );
    }, [props?.alertNames]);

    useEffect(() => {
        setSelectedOption(props.selectedOption);
    }, [props?.selectedOption]);

    const handleDropdownClose = (option) => {
        option && setSelectedOption(option);
        setOpen(false);
    };

    const handleResetClick = () => {
        setSelectedOption({});
        props.handleSelectChange("");
    };

    return (
        <div className="tabPanelContent">
            <GenericDropdown
                anchorEl={anchorRef.current}
                open={open}
                onClose={handleDropdownClose}
                selectedOption={selectedOption}
                options={_alertNames}
                onValueChange={(option) => props.handleSelectChange(option?.value)}
                setOpen={setOpen}
            />
            <div ref={anchorRef} className="menu-item-dropdown"></div>
            {!isEmpty(selectedOption) && <Typography variant="h6" className="selected-alerts"> {selectedOption}  </Typography>}
            <div className="reset-button" onClick={handleResetClick}>
                Reset
            </div>
        </div>
    );
}

export default  AlertNameContent