import { ArrowDropDown, KeyboardArrowDown, MoreVert } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {TIME_OPTIONS} from "../../../../Constant";
import { isEmpty } from "../../../../utils/helper";

function TopActionBar(props) {
  const [secondAnchorEl, setSecondAnchorEl] = useState(null);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState();

  const openSecond = Boolean(secondAnchorEl);

  useEffect(() => {
    const getDefaultValue = props?.filterConfig?.time
    const getSelectedTime = TIME_OPTIONS.find((data) => data.value === getDefaultValue)
    setSelectedTimeFilter(getSelectedTime);
  }, [props?.filterConfig?.time]);

  const handleSecondClick = (e) => {
    setSecondAnchorEl(e.currentTarget);
  };

  const handleSecondClose = (e, filter) => {
    if (isEmpty(filter)) return;
    if (filter !== "backdropClick") {
      setSelectedTimeFilter(filter);
      props.onTimeFilterChange(filter.value)
    }
    setSecondAnchorEl(null);
  };

  const renderNewFilterOptions = () => {
    return TIME_OPTIONS.map((option) => (
        <MenuItem
            onClick={(e) => handleSecondClose(e, option)}
            key={option.value}
            className={
              selectedTimeFilter?.value === option.value
                  ? "selected-menu-item"
                  : "menu-item"
            }
        >
          {option.label}
        </MenuItem>
    ));
  }


  return (
    <Grid container>
      <Grid item xs="10" className="amagi-header-typography">
          Alerts
      </Grid>
      <Grid item xs="2">
        <div>
          <div
              id="second-button"
              aria-controls={openSecond ? "new-menu" : undefined}
              aria-expanded={openSecond ? "true" : undefined}
              aria-haspopup="true"
          >
            <div className="flex-align-content flex-justify-content-end spacing-bottom">
              <div className="select-container" onClick={handleSecondClick}>
              <span className="select-selected-text">
                {selectedTimeFilter?.label}
              </span>
                <KeyboardArrowDown className="filled-dropdown-arrow" />
              </div>
            </div>
          </div>

          <Menu
              id="new-menu"
              anchorEl={secondAnchorEl}
              open={openSecond}
              onClose={handleSecondClose}
              MenuListProps={{
                "aria-labelledby": "second-button",
                className: "menu",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
          >
            {renderNewFilterOptions()}
          </Menu>
        </div>
      </Grid>
    </Grid>
  );
}

export default TopActionBar;
