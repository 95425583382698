import styled from "styled-components";
import { Tabs as MUITabs, Tab as MUITab } from "@mui/material";

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  color: white;
  width: 100%;
`;

export const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  overflow: scroll;
  width: 100%;
`;

export const StyledTabs = styled(MUITabs)`
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 30%;
  overflow-y: auto;
`;

export const StyledTab = styled(MUITab)`
  /* You can add any additional styles if needed */
`;

export const TabContent = styled.div`
  padding: 20px;
  min-width: 70%;
  overflow-y: auto;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
`;

export const StyledDrawer = styled.div`
  width: 94%;
  padding: 20px;
  overflow-x: auto;
`;

export const StyledButton = styled.button`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
  cursor: pointer;

  // Primary variant
  &.primary {
    background: #fda560;
    border: none;
    color: #0f0f0f;
  }

  // Secondary variant
  &.secondary {
    background: rgba(255, 255, 255, 0.08);
    border: none;
    color: #e7e7e7;
  }

  // Tertiary variant
  &.tertiary {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #e7e7e7;
  }
`;
