import { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { getJwtToken } from "../utils/localStorageHelper";
import { useEffect } from "react";

const AccessContext = createContext();

export const AccessProvider = ({ children }) => {
  const [accessData, setAccessData] = useState({});
  const [encodedAccessToken, setEncodedAccessToken] = useState("");
  const accessToken = getJwtToken();


  useEffect(() => {
    if (accessToken) {
      setEncodedAccessToken(accessToken);
      const decodedAccessToken = jwtDecode(accessToken);
      setAccessData(decodedAccessToken);
    }
  }, [accessToken]);

  return (
    <AccessContext.Provider
      value={{
        accessData,
        encodedAccessToken
      }}
    >
      {children}
    </AccessContext.Provider>
  );
};
export const useAccess = () => {
  const context = useContext(AccessContext);
  if (!context) {
    throw new Error("useAccess must be used within an AccessProvider");
  }
  return context;
};
