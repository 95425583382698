import axios from "axios";
import {ADS_OBSERVABILITY, INTERNAL_USER_AMG_ID} from "../Constant";
import {GetAlertsDataQuery} from "../query/getAlertsData";

export const getRulesData = (amg_id = INTERNAL_USER_AMG_ID, variableData = {}) => {
    const {epoch, channels, alertName } = variableData

    let data = JSON.stringify({
        query: GetAlertsDataQuery,
        variables: {"data":{"epoch":epoch,"alert_name":alertName,"channel_name":channels,"amg_id":amg_id}}
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: ADS_OBSERVABILITY,
        headers: {
            'identifier': 'getRulesData',
            'Content-Type': 'application/json'
        },
        data : data
    };

    return axios.request(config)
        .then((response) => {
            if(response?.data?.data && response.status === 200){
                return response.data.data
            }else {
                return []
            }
        })
        .catch((error) => {
            return error
        });
}