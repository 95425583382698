import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {BodySection, BottomSection, HeaderSection, StyledButton, StyledDrawer} from "./styles";
import {
  ALERT_NAME_SIDE_FILTER_VALUE,
  CHANNEL_SIDE_FILTER_VALUE, DEFAULT_FILTER_CONFIG,
  SIDE_FILTER_OPTIONS
} from "../../Constant";
import {CloseRounded, ExpandMore} from "@mui/icons-material";
import AlertNameContent from "./AlertNameContent";
import ChannelContent from "./ChannelContent";

function FilterDrawer(props) {
  const [localFilters, setLocalFilters] = useState(DEFAULT_FILTER_CONFIG);

  useEffect(() => {
    setLocalFilters(props.deafultFilters)
  }, [props?.deafultFilters])

  const handleResetFilters = () => {
    setLocalFilters(DEFAULT_FILTER_CONFIG);
  };

  const onAlertNameChanges = (value) => {
    const getLocalFilterState = Object.assign({}, localFilters)
    getLocalFilterState.alertName = value
    setLocalFilters(getLocalFilterState)
  }

  const onChannelNameChanges = (value) => {
    const getLocalFilterState = Object.assign({}, localFilters)
    getLocalFilterState.channels = value
    setLocalFilters(getLocalFilterState)
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.onClose}
      className="filter-drawer-content"
    >
      <StyledDrawer>
        <HeaderSection>
          <Typography variant="h6">
            Filters
          </Typography>
          <Button onClick={props.onClose}>
            <CloseRounded className='white-icon white-icon-hover'></CloseRounded>
          </Button>
        </HeaderSection>
        <BodySection>
          {
            SIDE_FILTER_OPTIONS.map((data) => {
              return(
                  <Accordion className="amg-accordion" key={data.value}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                      {data.label}
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        data.value === CHANNEL_SIDE_FILTER_VALUE ?
                            <ChannelContent
                                channelNames={props?.sideFiltersData?.channelsName}
                                handleOnChecked={onChannelNameChanges}
                                selectedChannels={localFilters.channels}
                                searchValue=""
                            />
                            : data.value === ALERT_NAME_SIDE_FILTER_VALUE ?
                              <AlertNameContent
                                  alertNames={props?.sideFiltersData?.alertName}
                                  selectedOption={localFilters.alertName}
                                  handleSelectChange={onAlertNameChanges}
                              />
                                : null
                      }
                    </AccordionDetails>
                  </Accordion>
              )
            })
          }
        </BodySection>
        <BottomSection>
          <StyledButton className="tertiary" onClick={() => props.onClose(false)} variant="outlined">
            Cancel
          </StyledButton>
          <StyledButton className="secondary" onClick={() => handleResetFilters()}>
            Reset
          </StyledButton>
          <StyledButton className="primary" onClick={() => props.onSaveFilters(localFilters)}>
            Apply
          </StyledButton>
        </BottomSection>
      </StyledDrawer>
    </Drawer>
  );
}

export default FilterDrawer;
