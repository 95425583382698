import {VAST_URL_REGEX} from "../Constant";

export const mapperData = (alertData = [], mappingData = []) => {
    let alertMappedData = []
    let uniqueChannelsAffected = {}
    let uniqueDemandTagAffected = {}
    let uniqueAlertNameAffected = {}

    if(alertData.length > 0 && mappingData.length > 0){

        alertData.map((rowData) => {
            if(rowData?.alert_level && rowData.alert_level === "SupplyTag"){
                let getDemandTagsData = []
                mappingData.map((data, index) => {
                    if(data.supply_tag_id === rowData.supply_tag_id) {
                        const demandTagIds = findMatchedID(data?.vast_endpoint_url)
                        if(demandTagIds !== ""){
                            getDemandTagsData.push(demandTagIds)
                        }
                    }
                    return true
                })
                alertMappedData.push( {...rowData, details: {...rowData.details , demandPartners: getDemandTagsData}} )
            }else if(rowData?.alert_level && rowData.alert_level === "DemandTag"){

                const mappedData = mappingData.find((data, index) => data.demand_tag_id ===  rowData.demand_tag_id && data)
                const demandTagId = findMatchedID(mappedData?.vast_endpoint_url)
                if(demandTagId !== ""){
                    alertMappedData.push({...rowData, details: {...rowData.details , demandPartners: [demandTagId]}})
                }else {
                    alertMappedData.push({...rowData, details: {...rowData.details , demandPartners: []}})
                }

            }

            if(rowData?.channel_name && uniqueChannelsAffected?.[rowData.channel_name]){
                uniqueChannelsAffected[rowData.channel_name] = uniqueChannelsAffected[rowData.channel_name] + 1
            }else if(rowData?.channel_name) {
                uniqueChannelsAffected[rowData.channel_name] = 1
            }

            if(rowData?.details?.demandPartners && rowData.details.demandPartners.length > 0){
                rowData.details.demandPartners.map((demandPartner) => {
                    if(uniqueDemandTagAffected?.[demandPartner]){
                        uniqueDemandTagAffected[demandPartner] = uniqueDemandTagAffected[demandPartner] + 1
                    }else {
                        uniqueDemandTagAffected[demandPartner] = 1
                    }

                    return true
                })
            }

            if(rowData?.rule_name && uniqueAlertNameAffected?.[rowData.rule_name]){
                uniqueAlertNameAffected[rowData.rule_name] = uniqueAlertNameAffected[rowData.rule_name] + 1
            }else if(rowData?.rule_name){
                uniqueAlertNameAffected[rowData.rule_name] = 1
            }

            return true
        })
    }

    return {alertMappedData, uniqueChannelsAffected, uniqueDemandTagAffected , uniqueAlertNameAffected}
}

const findMatchedID = (vastUrl) => {
    let matchedValue = "";

    for (const regex of VAST_URL_REGEX) {
        const match = regex.exec(vastUrl);
        if (match) {
            matchedValue = match[1];
            break;
        }
    }

    return matchedValue
}
