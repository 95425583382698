import { KeyboardArrowDown } from "@mui/icons-material";
import React from "react";
import {isEmpty} from "../../../utils/helper"

function DropdownButton({ onClick, selectedLabel, icon: IconComponent = KeyboardArrowDown }) {
  return (
    <div onClick={onClick} className="generic-dropdown-container">
      <span className="generic-dropdown-selected-option-label">
          {isEmpty(selectedLabel)  ? "Select": `${selectedLabel}` }
      </span>
      <IconComponent className="generic-dropdown-arrow" />
    </div>
  );
}

export default DropdownButton;
