import React, { useEffect, useState } from "react";
import { Card, Grid, Typography, Button, Skeleton } from "@mui/material";
import {
  AMAGI_BRANDING_TEXT,
  AMAGI_LOGO,
  USER_DATA_KEY,
  USER_NOT_VALID,
} from "../../Constant";
import {
  getLocalStorageData,
  setLocalStorageData,
  setJwtToken,
} from "../../utils/localStorageHelper";
import Notification from "../Notification";
import { calculateTimeFromNow, getCurrentTime } from "../../utils/helper";
import FallBackRender from "../FallBackRender";
import { ErrorBoundary } from "react-error-boundary";
import { useAuth0 } from "@auth0/auth0-react";
import LoopIcon from "@mui/icons-material/Loop";
import { jwtDecode } from "jwt-decode";
import { useAccess } from "../../contexts/AccessContext";

function Login(props) {
  const [showNotification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isDataValidating, setIsDataValidating] = useState(false);

  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();
  useEffect(() => {
    const getLocalUserData = getLocalStorageData(USER_DATA_KEY, true);
    const getCurrentEpochTime = getCurrentTime();
    if (
      getLocalUserData?.expires_on &&
      getLocalUserData.expires_on > getCurrentEpochTime
    ) {
      window.location.href = "/dashboard";
    } else if (isAuthenticated) {
      setIsDataValidating(true);
      validateToken();
    } else {
      setNotification(false);
      setNotificationMessage(USER_NOT_VALID);
      setIsDataValidating(false);
    }
  }, [isAuthenticated]);

  const onClickLoginButton = () => {
    loginWithRedirect();
    setIsDataValidating(true);
  };

  const validateToken = async () => {
    const data = await getIdTokenClaims();
    const accessToken = await getAccessTokenSilently();
    const futureEpochTime = calculateTimeFromNow();
    setLocalStorageData(USER_DATA_KEY, true, {
      ...data,
      expires_on: futureEpochTime,
    });
    setJwtToken(accessToken);
    setIsDataValidating(false);
    window.location.href = "/dashboard"; // TODO: use router redirection
  };

  const onHandleCloseData = () => {
    setNotification(false);
    setNotificationMessage("");
  };

  return (
    <ErrorBoundary FallbackComponent={FallBackRender}>
      <Grid container className="login-component">
        <Grid item xs={12} className="amagi-branding">
          <img src={AMAGI_LOGO} alt="amagi" className="amagi-branding-logo" />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} className="amagi-branding-div">
              <Grid container>
                <Grid item xs={8}>
                  <Typography className="amagi-branding-text">
                    {AMAGI_BRANDING_TEXT}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" className="amagi-card-login">
                <Grid container>
                  <Grid item xs={12}>
                    <img
                      src={AMAGI_LOGO}
                      alt="amagi"
                      className="amagi-login-branding"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} className="text-align-center">
                        <Button
                          variant="contained"
                          className="filled-submit-button"
                          size="medium"
                          onClick={() => onClickLoginButton()}
                        >
                          {" "}
                          Sign In{" "}
                        </Button>
                        {isDataValidating && (
                          <LoopIcon className="loading-icon" />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Notification
          open={showNotification}
          message={notificationMessage}
          handleClose={onHandleCloseData}
        />
      </Grid>
    </ErrorBoundary>
  );
}

export default Login;
