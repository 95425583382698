export const getLocalStorageData = (key = "", parse = false) => {
  const getLocalData = localStorage.getItem(key);

  if (parse && getLocalData) {
    return JSON.parse(getLocalData);
  }

  return getLocalData;
};

export const setLocalStorageData = (key = "", parse = false, data = {}) => {
  let setLocalData = parse ? JSON.stringify(data) : data;

  localStorage.setItem(key, setLocalData);

  return true;
};

export const removeLocalStorageData = (key = "") => {
  localStorage.removeItem(key);

  return true;
};

//storage for access Token

export const setJwtToken = (jwtToken) => {
  setLocalStorageData("access", false, jwtToken);
};
export const getJwtToken = () => {
  return getLocalStorageData("access", false);
};
export const removeJwtToken = () => {
  removeLocalStorageData("access");
};
