import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import { ADS_OBSERVABILITY } from "../Constant";

export function createApolloClient(user) {
  const httpLink = createHttpLink({
    uri: ADS_OBSERVABILITY,
    headers: {
      amg_id: user?.organization_metadata?.amg_id,
    },
  });

  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });
}
