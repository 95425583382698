import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Grid, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";

import { CHANGE_TIME_FORMAT } from "../../../Constant.js";
import { ROLE_LABELS } from "../../../Constant.js";
import DownloadIcon from "../../../icons/DownloadIcon.js";
import SearchIcon from "../../../icons/SearchIcon";
import { useGetUsersData } from "../../../query/getUser.js";
import UserDeletePop from "../UserDelete/index.js";
import {isEmpty} from "../../../utils/helper";

const UserDetailList = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { usersLoading, usersError, usersData, usersRefetch } = useGetUsersData();
  const getRoleLabel = (role) => ROLE_LABELS[role] || role;

  useEffect(() => {
    if (usersData?.getAllUsers) {
      const updatedFilteredUsers = usersData.getAllUsers.filter(
        (user) =>
          user.customer_id.toLowerCase().includes(searchText.toLowerCase()) ||
          user.user_id.toLowerCase().includes(searchText.toLowerCase()) ||
          getRoleLabel(user.user_role)
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
      setFilteredUsers(updatedFilteredUsers);
    }
  }, [usersData, searchText]);

  return (
      <Grid conatiner className="amagi-body-div">
        <Grid container className="listing-container listing-container-users">
          <Grid item xs={12} className="listing-filter-container">
            <div className="table-action-bar-top">
              <div>
                <Typography className="off-white-text">
                  {usersData?.getAllUsers?.length || 0} user
                </Typography>
              </div>

              <div className="generic-user-container-actionbar">
                <TextField
                  value={searchText}
                  onChange={(e) => setSearchText(e?.target?.value)}
                  placeholder={`Search`}
                  variant="outlined"
                  className="generic-search-container"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon></SearchIcon>
                      </InputAdornment>
                    ),
                  }}
                />
                <DownloadIcon data={usersData?.getAllUsers} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="listing-table-container">
            <Table className="white-table">
              <TableHead>
                <TableCell width="8%">
                  <div style={{ display: "flex" }}>
                    NAME
                    <KeyboardArrowDownIcon className="keydown-icon" />
                  </div>
                </TableCell>
                <TableCell width="6%">
                  {" "}
                  <div style={{ display: "flex" }}>
                    EMAIL
                    <KeyboardArrowDownIcon className="keydown-icon" />
                  </div>
                </TableCell>
                <TableCell width="4%">
                  {" "}
                  <div style={{ display: "flex" }}>
                    ROLE
                    <KeyboardArrowDownIcon className="keydown-icon" />
                  </div>
                </TableCell>
                <TableCell width="7%">
                  {" "}
                  <div style={{ display: "flex" }}>
                    USER ADDED ON
                    <KeyboardArrowDownIcon className="keydown-icon" />
                  </div>
                </TableCell>
                <TableCell width="6%">
                  {" "}
                  <div style={{ display: "flex" }}>
                    LAST LOGIN
                    <KeyboardArrowDownIcon className="keydown-icon" />
                  </div>
                </TableCell>
                <TableCell width="4%">
                  {" "}
                  <div style={{ display: "flex" }}>
                    STATUS
                    <KeyboardArrowDownIcon className="keydown-icon" />
                  </div>
                </TableCell>
                <TableCell width="1%"></TableCell>
              </TableHead>
              <TableBody>
                {
                  filteredUsers.map((data, index) => {
                     return (
                        <TableRow key={index} className="data-row">
                          <TableCell>
                            <div style={{ display: "flex" }}>
                              <Avatar className="username-avatar">
                                {data.customer_id ?
                                    data.customer_id
                                        .split(" ")
                                        .map((word, index) => (index < 2 ? word[0] : ""))
                                        .join("")
                                    : null
                                }
                              </Avatar>
                              <div className="user-name">{data?.customer_id && !isEmpty(data.customer_id) ? data.customer_id : "N/A"}</div>
                            </div>
                          </TableCell>
                          <TableCell>{data?.user_id && !isEmpty(data.user_id) ? data.user_id : "N/A"}</TableCell>
                          <TableCell>{getRoleLabel(data?.user_role)}</TableCell>
                          <TableCell>{CHANGE_TIME_FORMAT(data?.created_at)}</TableCell>
                          <TableCell>{CHANGE_TIME_FORMAT(data?.updated_at)}</TableCell>
                          <TableCell style={{ color: "green" }}>
                            <div style={{ display: "flex" }}>
                              <CheckCircleOutlineIcon className="user-active" />
                              Active
                            </div>
                          </TableCell>
                          <TableCell>
                            <UserDeletePop
                                userEmail={data?.user_id}
                                userRole={data?.user_role}
                            />
                          </TableCell>
                        </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
  );
};

export default UserDetailList;
