import * as React from "react";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Typography, IconButton, Grid } from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useDeleteConfirm } from "../../../contexts/DeleteUserConfirmContext";

export default function UserDeletePop({ userEmail, userRole }) {
  const { handleClickOpenDelete, setHeading, setMessage, setBtnName, setUserEmail, setUserRole } =
    useDeleteConfirm();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        style={{ color: "white" }}
        onClick={handleClick}
      >
        <MoreVertIcon className="vert-dot-user" />
      </IconButton>
      <Popover
        className="custom-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
          <Grid
            container
            onClick={() => {
              handleClickOpenDelete();
              setHeading("Update Permission");
              setMessage(
                "Are you sure you want to update the permission for the selected users?"
              );
              handleClose();
              setBtnName("Update");
              setUserEmail(userEmail)
              setUserRole(userRole)
            }}
            className="clickable-grid-popover"
          >
            <VpnKeyIcon className="vpnkey-icon" />
            <Typography className="pop-typography" >Change Permission</Typography>
          </Grid>

          <Grid
            container
            sx={{ marginRight: 1 }}
            onClick={() => {
              handleClickOpenDelete();
              setHeading("Delete User");
              setMessage("Are you sure you want to delete this user?");
              handleClose();
              setBtnName("Delete");
              setUserEmail(userEmail)
              setUserRole(userRole)
            }}
            className="clickable-grid-popover"
          >
            <DeleteForeverOutlinedIcon />
            {/* Issue */}
            <Typography className="pop-typography" >
              Delete User
            </Typography>
          </Grid>
        </div>
      </Popover>
    </div>
  );
}
