import React, {useEffect, useState} from "react";
import UserSearch from "./UserDetailList";
import Header from "../Content/Header";
import TopAction from "./TopAction";
import SideBar from "./SideBar";
import { SidebarProvider } from "../../contexts/SideBarContext";
import { DeleteConfirmProvider } from "../../contexts/DeleteUserConfirmContext";
import DeleteUpdatePopover from "./DeleteUpdatePopover";
import { UserProvider } from "../../contexts/UserContext";
import { useNavigate } from 'react-router-dom'
import {ADMIN_USER_ROLE, INITIAL_BASE_URL, USER_DATA_KEY} from "../../Constant";
import {getLocalStorageData} from "../../utils/localStorageHelper";


const UserBody = () => {
  const [userData, setUserData] = useState({})
  const navigate = useNavigate

  useEffect(() => {
    const getLocalUserData = getLocalStorageData(USER_DATA_KEY, true)

    if (getLocalUserData?.user_roles?.includes(ADMIN_USER_ROLE)) {

      setUserData(getLocalUserData)
    }else {
      navigate(INITIAL_BASE_URL)
    }
  }, [])

  return (
    <React.Fragment>
      <UserProvider>
        <DeleteConfirmProvider>
          <SidebarProvider>
            <Header userData={userData}/>
            <TopAction />
            <SideBar />
            <UserSearch />
            <DeleteUpdatePopover />
          </SidebarProvider>
        </DeleteConfirmProvider>
      </UserProvider>
    </React.Fragment>
  );
};

export default UserBody;
