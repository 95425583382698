import { gql, useQuery } from '@apollo/client';
import { useEffect } from '@apollo/client';

const GET_USER_ID = gql`
query MyQuery($data: AuthGetUserId = {}) {
   getUserId(data: $data) {
    email
    email_verified
    user_id
  }
}
`;

export const useGetUserId = (UserIdData) => {
  const { loading, error, data, refetch } = useQuery(GET_USER_ID, {
    variables: {
      "data": {
        ...UserIdData
      }
    },
    context: {
      headers: {
        'identifier': 'getUserId'
      }
    }

  })

  console.log("DATA", data)

  return { loading, error, data, userIdRefetch: refetch }
}
// import { useEffect } from 'react';

// const GET_USER_ID = gql`
//   query MyQuery($data: AuthGetUserId = {}) {
//     getUserId(data: $data) {
//       email
//       email_verified
//       user_id
//     }
//   }
// `;

// const GetUserByIdComponent = ({ userIdData }) => {
//   const { loading, error, data, refetch } = useQuery(GET_USER_ID, {
//     variables: {
//       data: { ...userIdData },
//     },
//     context: {
//       headers: {
//         identifier: 'getUserId',
//       },
//     },
//   });

//   useEffect(() => {
//     console.log('DATA', data);
//   }, [data]);

//   return { loading, error, data, refetch };
// };

// export default GetUserByIdComponent;



