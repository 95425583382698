import React from "react";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { useSidebar } from "../../../contexts/SideBarContext";
import AddIcon from "@mui/icons-material/Add";

const TopAction = () => {
  const { openSidebar } = useSidebar();
  return (
    <Grid container>
      <Grid item xs="10" className="amagi-header-typography" padding="16px">
        User management
      </Grid>
      <Grid className="create-button-grid" item xs="2">
        <Button
          variant="contained"
          className="create-user-button"
          style={{ marginRight: "8px", whiteSpace: "nowrap" }}
          onClick={openSidebar}
        >
          <div
            className="add-user-text"
            style={{ display: "flex", alignItems: "center" }}
          >
            <AddIcon className="add-icon" /> Add User
          </div>
        </Button>
      </Grid>
    </Grid>
  );
};

export default TopAction;
