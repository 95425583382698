const SearchIcon = ({onClick}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.47 10.6346L14.0001 13.1641L13.1642 14L10.6347 11.4699C9.69355 12.2244 8.52288 12.6348 7.31662 12.6331C4.3819 12.6331 2.00009 10.2513 2.00009 7.31653C2.00009 4.38181 4.3819 2 7.31662 2C10.2513 2 12.6332 4.38181 12.6332 7.31653C12.6349 8.52279 12.2245 9.69346 11.47 10.6346ZM10.285 10.1963C11.0347 9.42535 11.4534 8.39191 11.4517 7.31653C11.4517 5.0316 9.60096 3.18145 7.31662 3.18145C5.0317 3.18145 3.18154 5.0316 3.18154 7.31653C3.18154 9.60087 5.0317 11.4516 7.31662 11.4516C8.392 11.4533 9.42545 11.0346 10.1964 10.2849L10.285 10.1963Z"
        fill="#E7E7E7"
      />
    </svg>
  );
};

export default SearchIcon
