import {AMG_REGEX, CHANNEL_MAPPING_NOT_FOUND, JIRA_URL_PREFIX, JOB_RUN_MAPPING, MAX_LENGTH} from "../Constant";

export const isEmpty = (data = "") => {
  if (data && typeof data === "string" && data !== "") {
    return false;
  }

  if (
    data &&
    typeof data === "object" &&
    Array.isArray(data) &&
    data.length > 0
  ) {
    return false;
  }

  if (data && typeof data === "object" && Object.keys(data).length > 0) {
    return false;
  }

  if (data && typeof data === "number" && data !== undefined) {
    return false;
  }

  return true;
};

export const convertEpochToDate = (epoch = "") => {
  if (epoch && epoch !== "") {
    const epochMilliseconds = epoch * 1000;

    // Create a new Date object
    const date = new Date(epochMilliseconds);

    // Array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get individual components of the date
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()]; // Months are zero-based, so adding 1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format the date as desired
    const formattedDate = `${month} ${day} ${year}, ${hours}:${minutes}`;

    return formattedDate;
  }

  return "Null";
};

export const convertUTCTODate = (utc = "") => {
  if (utc && utc !== "") {
    // Create a new Date object with the UTC date and time
    const date = new Date(utc);

    // Array of day names
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract date components
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()]; // Months are zero-based, so adding 1
    const day = date.getDate();
    const dayOfWeekNumber = date.getDay();
    const dayOfWeekName = dayNames[dayOfWeekNumber];

    // Extract time components
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formatedDate = `${month} ${day} ${year}, ${hours}:${minutes} `;

    return formatedDate;
  }

  return "Null";
};

export const copyToClipBoard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard
      .writeText(text)
      .then(() => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand("copy");
      return true;
    } catch (error) {
      return false;
    } finally {
      textArea.remove();
    }
  }
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const trimString = (str = "", length = MAX_LENGTH) => {
  if (str.length > length && str.includes("amg")) {
    const newString = str.replace(AMG_REGEX, "")
    const getUpdatedString = newString?.replace(/_/g, " ")?.split('|')[1]?.trim() || newString?.replace(/_/g, " ")
    return `${getUpdatedString.slice(0, length)}...`;
  } else if (str.length > length) {
    const getUpdatedString = str?.replace(/_/g, " ")?.split('|')[1]?.trim() || str?.replace(/_/g, " ")
    return `${getUpdatedString.slice(0, length)}...`;
  }else {
    const getUpdatedString = str?.replace(/_/g, " ")?.split('|')[1]?.trim() || str?.replace(/_/g, " ")
    return  getUpdatedString
  }
};

export function filterData(data, filterText) {
  if (!filterText || !filterText.length) return data;
  const lowerCaseFilterText = filterText.toLowerCase();

  const filteredData = data.filter(
    (item) =>
      item?.channel_name?.replace(/\s/g, "")?.toLowerCase().includes(lowerCaseFilterText) ||
      item?.rule_name?.replace(/\s/g, "")?.toLowerCase().includes(lowerCaseFilterText) ||
      item?.alert_level?.replace(/\s/g, "")?.toLowerCase().includes(lowerCaseFilterText) ||
      item?.rule_details?.Title?.replace(/\s/g, "")?.toLowerCase().includes(lowerCaseFilterText) ||
      item?.rule_details?.Formula?.replace(/\s/g, "")?.toLowerCase().includes(lowerCaseFilterText)
  );

  return filteredData;
}

export function calculateTimeFromNow(seconds = 86400) {
  const now = new Date();
  const futureTime = new Date(now.getTime() + seconds * 1000);
  return Math.floor(futureTime.getTime() / 1000);
}

export const getCurrentTime = () => {
  return Math.floor(Date.now() / 1000);
};

export function decodeJwtToken(token) {
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid JWT token format");
  }

  const decodedPayload = atob(parts[1]);
  return JSON.parse(decodedPayload);
}

export const removeString = (string) => {
  if (string.includes("(PT)")) {
    return string.replace("(PT)", "").replace("_", "");
  } else if (string.includes("(CT)")) {
    return string.replace("(CT)", "").replace("_", "");
  } else {
    return string;
  }
};

export const getKeyType = (string) => {
  if (string.includes("(PT)")) {
    return "past_value";
  } else if (string.includes("(CT)")) {
    return "current_value";
  } else {
    return "current_value";
  }
};

export const formatedJSON = (jsonData = {}) => {
  const formatedData = {
    alert_name: jsonData.rule_details?.Title || "N/A",
    date_time: convertEpochToDate(jsonData?.epoch) || "N/A",
    alert_type: jsonData?.validation_type || "N/A",
    channel_name: jsonData?.channel_name || "N/A",
    demand_partners:
      jsonData?.details?.demandPartners?.length > 0
        ? jsonData.details.demandPartners.map((name) => name)
        : "N/A",
    rule_formula: jsonData?.rule_details?.Formula || "N/A",
    last_job_run: convertUTCTODate(jsonData?.details?.jobLastRun) || "N/A",
    job_run_frequency: JOB_RUN_MAPPING[jsonData?.aggregation_level] || "N/A",
    metrics_deviation: jsonData.details?.metricDeviation || "N/A",
    metrics:
      jsonData?.metricsData && !isEmpty(jsonData.metricsData)
        ? Object.keys(jsonData.metricsData).map((key) => ({
          key: key,
          current_value: jsonData.metricsData[key]?.current_value || "N/A",
          past_value: jsonData.metricsData[key]?.past_value || "N/A",
        }))
        : "N/A",
    action_items:
      jsonData?.actionItems?.length > 0
        ? jsonData.actionItems.filter((data, index) => index !== 0 && data)
        : "N/A",
  };

  return formatedData;
};

export const onClickJiraId = (jiraId = "") => {
  if (isEmpty(jiraId)) return;
  window.open(JIRA_URL_PREFIX + jiraId, "_blank");
};
export const formatValue = (value) => {
  if (value) {
    value = Number(value)
    return value.toLocaleString();
  }

  return "N/A"
};

export const getLastEpoch = (value = "72hrs") => {
  const twentyFourHoursAgo = new Date();
  let epoch = 0

  switch (value){
    case "24hrs":
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
      epoch = Math.floor(twentyFourHoursAgo.getTime() / 1000);
      break
    case "72hrs":
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 72);
      epoch = Math.floor(twentyFourHoursAgo.getTime() / 1000);
      break
    case "7days":
      twentyFourHoursAgo.setDate(twentyFourHoursAgo.getDate() - 7);;
      epoch = Math.floor(twentyFourHoursAgo.getTime() / 1000);
      break
    default:
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 72);
      epoch = Math.floor(twentyFourHoursAgo.getTime() / 1000);
      break
  }

  return epoch
}

export const getUniqueChannlesAndRules = (data = []) => {
  let channelsName = []
  let alertName = []

  if(data.length > 0){
    data.map((rowData) => {
      if(rowData?.channel_name && !channelsName.includes(rowData.channel_name) && channelsName !== CHANNEL_MAPPING_NOT_FOUND){
        channelsName.push(rowData.channel_name)
      }

      if(rowData?.rule_name && !alertName.includes(rowData.rule_name)){
        alertName.push(rowData.rule_name)
      }

      return true
    })
  }

  return {channelsName, alertName}
}
