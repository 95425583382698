import React from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import styled from "styled-components";
import { useFilters } from "../contexts/FilterContext";
import { isEmpty } from "../utils/helper";
import IconContainer from "./IconContainer";
import { downloadCSV } from "../utils/csvDownload";

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 10px;
    right: 10px;
    background-color: #fda560; // Set the badge color to white
  }
`;

const StyledIconButton = styled(IconButton)`
  color: white;
  padding: 0;
`;

export const FilterIcon = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.46847 6.66667H11.8018L7.8018 10.6667L3.8018 6.66667H7.13513V2H8.46847V6.66667ZM2.46847 12.6667H13.1351V8H14.4685V13.3333C14.4685 13.5101 14.3982 13.6797 14.2732 13.8047C14.1482 13.9298 13.9786 14 13.8018 14H1.8018C1.62499 14 1.45542 13.9298 1.33039 13.8047C1.20537 13.6797 1.13513 13.5101 1.13513 13.3333V8H2.46847V12.6667Z"
        fill="#E7E7E7"
      />
    </svg>
  );
};

function DownloadIcon({ data }) {
  const handleDownloadCSV = () => {
    const prefix = "Ads-Observability-User-";
    const timestamp = new Date().getTime();
    const filename = `${prefix}${timestamp}`;
    downloadCSV(data, filename);
  };
  return (
    <StyledBadge>
      <StyledIconButton onClick={() => handleDownloadCSV()}>
        <IconContainer>
          <FilterIcon />
        </IconContainer>
      </StyledIconButton>
    </StyledBadge>
  );
}

export default DownloadIcon;
