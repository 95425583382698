import { gql, useMutation } from '@apollo/client';

const CREATE_USER = gql`
  mutation MyMutation($userData: UserInput = {}) {
  createUser(userData: $userData) {
    created_at
    customer_id
  }
  }
`;

export const CreateUser = () => {
  const [createUser, { loading, error }] = useMutation(CREATE_USER);

  const handleCreateUser = async (userData) => {
    try {
      const response = await createUser({
        variables: {
          ...userData
        },
      });
      console.log('User created:', response.data.createUser);
      return response.data.createUser;

    } catch (mutationError) {
      console.error('Error creating user:', mutationError);
      return { error: mutationError.message };
    }
  };

  return { handleCreateUser, loading, error };
};
