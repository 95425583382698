import { gql, useMutation } from '@apollo/client';

const DELETE_USER = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId)
     
    
  }
`;

export const useDeleteUser = () => {
    const [deleteUser, { loading, error }] = useMutation(DELETE_USER);

    const handleDeleteUser = async (userId) => {
        try {
            const { data } = await deleteUser({
                variables: userId,
            });
            return true;
        } catch (mutationError) {
            console.error('Error deleting user:', mutationError);
            return false;
        }
    };

    return { handleDeleteUser, loading, error };
};