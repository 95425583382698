import { gql, useMutation } from '@apollo/client';

const DELETE_USER = gql`
mutation MyMutation($DeleteUserRoleData: AuthDeleteUserRole = {}) {
  deleteUserRoleControlPlane(DeleteUserRoleData: $DeleteUserRoleData)
}
`;

export const DeleteUserRole = () => {
    const [deleteUserRoleControlPlane, { loading, error }] = useMutation(DELETE_USER);

    const handleDeleteUserRole = async (DeleteUserRoleData) => {
        try {
            const response = await deleteUserRoleControlPlane({
                variables: {
                    ...DeleteUserRoleData
                },
            });
            return true;
        } catch (mutationError) {
            console.error('Error deleting user:', mutationError);
            return false;
        }
    };

    return { handleDeleteUserRole, loading, error };
};