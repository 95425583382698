export const  GetSupplyDemandTagsMappingQuery = `
    query MyQuery ($id: String = "") {
      getSupplyDemandTagMapping (id: $id){
        customer_id
        demand_tag_id
        name
        supply_tag_id,
        vast_endpoint_url
      }
    }`
