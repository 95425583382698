import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { useState } from "react";

import { useSidebar } from "../../../contexts/SideBarContext";
import { CreateUser } from "../../../query/createUser";
import { useGetUsersData } from "../../../query/getUser.js";
import { SendInvite } from "../../../query/controlPlane/sendInvite.js";
import { useGetAuthToken } from "../../../query/controlPlane/getAuthToken.js";
import { useAccess } from "../../../contexts/AccessContext.js";

export default function TemporaryDrawer() {
  const { AuthTokenData } = useGetAuthToken();
  const { isSidebarOpen, closeSidebar } = useSidebar();
  const { handleCreateUser } = CreateUser();
  const { usersData, usersRefetch } = useGetUsersData();
  const { handleSendInvite } = SendInvite();
  const { accessData } = useAccess();


  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userExistsError, setUserExistsError] = useState();



  const onClickSendInvite = async () => {

    const userExists = usersData?.getAllUsers?.some(user => user.user_id === userEmail);
    userExists ? setUserExistsError(true) : setUserExistsError(false)
    try {
      const inviteResponse = await handleSendInvite(
        {

          sendInviteData: {
            org_id: accessData?.org_id,
            productID: accessData?.controlplane_metadata?.productID,
            role: userRole,
            tenantName: accessData?.controlplane_metadata?.tenantName,
            token: AuthTokenData.getAuthToken.access_token,
            userEmail: userEmail,
            workspaceName: accessData?.controlplane_metadata?.workspaceName
          }
        }
      )
      if (inviteResponse) {
        const addUserResponse = await handleCreateUser(
          {
            userData: {
              customer_id: userName,
              user_id: userEmail,
              user_role: userRole
            },
          }
        );
        closeSidebar();
        if (addUserResponse) {
          usersRefetch();
        }
      }
    } catch (error) {
      console.error("Error while sending invite:", error);
    }

  };

  return (
    <Drawer
      className="sidenav-add-user"
      anchor="right"
      open={isSidebarOpen}
      onClose={closeSidebar}
    >
      <Grid container>
        <Grid item xs="10" className="amagi-header-typography" padding="20px">
          Add User
        </Grid>
      </Grid>
      <Divider className="divider-user-management" />
      <Box sx={{ width: "500px" }} role="presentation">
        <div className="sidebar-mid">
          <List>
            <Grid container item xs={12} alignItems="center">
              <ListItem>
                <span className="add-user-span">Name</span>
                <TextField
                  className="text-field-add-user"
                  label="Name"
                  id="outlined-required"
                  variant="standard"
                  size="medium"
                  fullWidth
                  margin="normal"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </ListItem>
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <ListItem>
                <span>Email ID*</span>
                <TextField
                  className="text-field-add-user"
                  label="Email"
                  style={{ marginLeft: "55px" }}
                  variant="standard"
                  id="outlined-required"
                  size="medium"
                  fullWidth
                  margin="normal"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  error={userExistsError}
                  helperText={userExistsError ? "User with this email already exists!" : ""}
                  required
                />
              </ListItem>
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <ListItem>
                <span>Role*</span>
                <TextField

                  select
                  className="text-field-add-user"
                  label="Role"
                  id="outlined-required"
                  variant="standard"
                  size="medium"
                  fullWidth
                  margin="normal"
                  value={userRole}
                  onChange={(e) => setUserRole(e.target.value)}
                  required
                >
                  <MenuItem key="admin" className="role-dropdown" value="rol_HM3zwrpqi0npPNAQ">Admin</MenuItem>
                  <MenuItem key="user" value="rol_wXO5CcdjvkeIa142">User</MenuItem>
                </TextField>
              </ListItem>
            </Grid>
          </List>
        </div>
        <div style={{ height: "64px" }}>
          <Divider className="divider-user-management" />
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              className="cancel-button"
              style={{ marginRight: "10px" }}
              onClick={closeSidebar}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="create-button"
              style={{ marginRight: "15px" }}
              onClick={() => {
                onClickSendInvite();

              }}
            >
              Add
            </Button>
          </Grid>
        </div>
      </Box>
    </Drawer>
  );
}
