import { gql, useQuery } from "@apollo/client";

const GET_AUTH_TOKEN = gql`
  query GetAuthTokenQuery {
    getAuthToken {
        access_token
        expires_in
        scope
        success
        token_type
    }
}
`;

export const useGetAuthToken = () => {
    const { loading, error, data, refetch } = useQuery(GET_AUTH_TOKEN, {
        context: {
            headers: {
                identifier: 'getAuthToken',
            },
        },
    });

    return { usersLoading: loading, usersError: error, AuthTokenData: data, usersRefetch: refetch };
};
