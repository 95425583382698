import React from "react"
import {Grid, Skeleton} from "@mui/material";

function Loading(props){

    const element  = []
    let i = 0

    while(i < props.rowCount ){
        element.push(
            <Skeleton variant="rounded" className="filter-data-skeleton" key={i}/>
        )
        i ++
    }

    return(
        <Grid item xs={12}>
            {element}
        </Grid>
    )
}

export default Loading