import React from "react";
import {ALERT_ICONS} from "../Constant";

function SeverityIcon() {
  return (
    <div>
      <img src={ALERT_ICONS} alt="severity" />
    </div>
  );
}

export default SeverityIcon;
