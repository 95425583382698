import { gql, useMutation } from '@apollo/client';

const SEND_INVITE = gql`
   mutation MyMutation($sendInviteData: AuthSendInvite = {
   }) {
  sendInvite(sendInviteData: $sendInviteData)
}
`;

export const SendInvite = () => {
    const [sendInvite, { loading, error }] = useMutation(SEND_INVITE);

    const handleSendInvite = async (sendInviteData) => {
        try {
            console.log("REACHED")
            const response = await sendInvite({
                variables: {
                    ...sendInviteData
                },
            });
            console.log('Invite Sent:', response);
            return response;

        } catch (mutationError) {
            console.error('Error while sending Invite:', mutationError);
            return { error: mutationError.message };
        }
    };

    return { handleSendInvite, loading, error };
};
