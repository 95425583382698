export const GetAlertsDataQuery = `
    query MyQuery($data: GetRulesDataInput = {}) {
      getRulesData(data: $data) {
        aggregation_level
        alert_group
        alert_id
        alert_level
        alerting_mode
        alerting_notification_id
        channel_name
        demand_tag_id
        details {
          jobLastRun
        }
        epoch
        last_successful_epoch
        metrics
        rule_details {
          Formula
          NextSteps
          Title
        }
        rule_name
        status
        supply_tag_id
        validation_type
      }
    }`
