import React from "react";
import {
    convertEpochToDate,
    isEmpty,
    onClickJiraId,
    trimString,
} from "../../../../../utils/helper";
import { Button, TableCell, TableRow, Tooltip, TableBody } from "@mui/material";
import SeverityIcon from "../../../../../icons/SeverityIcon";
import {CHANNEL_MAPPING_NOT_FOUND, MAX_DEMAND_TAGS_VIEW} from "../../../../../Constant";

function TableBodyComponent(props) {
    return (
        <TableBody>
            {!isEmpty(props.alertData) && props.alertData.map((rowData, index) => {
                return (
                    <TableRow key={`${rowData?.alert_id}-${rowData?.epoch}-${index}`} className="data-row">
                        <TableCell width="11%">
                            {convertEpochToDate(rowData?.epoch)}
                        </TableCell>
                        <TableCell width="5%" align="center"> <SeverityIcon className="error-alerting" /> </TableCell>
                        <TableCell width="11%"> <span className="alert-group-tag"> Monetization </span> </TableCell>
                        <TableCell width={props.isInternal ? "18%" : "14%"} className="text-cammel-case">
                            {rowData?.channel_name !== CHANNEL_MAPPING_NOT_FOUND ? trimString(rowData?.channel_name, 30) : "N/A"}
                        </TableCell>
                        <TableCell
                            width="20%"
                            onClick={() => props.onClickRow(index)}
                        >
                            <span className="text-primary">
                                <Tooltip title={rowData.rule_details?.Formula}>
                                    {trimString(rowData?.rule_details?.Title, 30)}
                                </Tooltip>
                            </span>
                            <span className="text-description">
                                {
                                    trimString(rowData.rule_details?.Formula, 40)
                                }
                            </span>
                        </TableCell>
                        <TableCell width="7%"> {rowData?.alert_level && rowData.alert_level === "SupplyTag" ? "Channel" : "Demand Tag"} </TableCell>
                        {props.isInternal ? (
                            <TableCell width="18%">
                                {rowData?.alerting_notification_id ? (
                                    <Button
                                        sx={{ minWidth: "110px" }}
                                        variant="contained"
                                        onClick={() =>
                                            rowData?.alerting_notification_id &&
                                            onClickJiraId(rowData?.alerting_notification_id)
                                        }
                                    >
                                        {rowData?.alerting_notification_id || "NA"}
                                    </Button>
                                ) : "NA"}
                            </TableCell>
                        ) : (
                            <TableCell width="28%">
                                {rowData?.details?.demandPartners && !isEmpty(rowData.details.demandPartners) ?
                                    rowData.details.demandPartners.map((demand_data, index) => (
                                    index < MAX_DEMAND_TAGS_VIEW ? <span className="demand-tag-span" key={`${demand_data}-${index}`}><Tooltip title={demand_data} >{trimString(demand_data)}</Tooltip></span> : null
                                    )) : "N/A"
                                }
                            </TableCell>
                        )}
                    </TableRow>
                );
            })}
        </TableBody>
    )
}

export default TableBodyComponent;
