import React, {useEffect, useState} from "react"
import {Grid, Typography} from "@mui/material";
import {isEmpty} from "../../../../utils/helper";
import Loading from "../../../Loading";

function FilterView(props){
    const [totalAlertsCount, setAlertsCount] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        if(!isEmpty(props.data)){
            setAlertsCount(props.data.length)
            setLoading(false)
        }
    }, [props?.data])

    return(
        <Grid container>
            <Grid item xs={12}>
                {
                    loading && <Loading rowCount={1} />
                }
                {
                    !loading && (
                        <Grid container justifyContent="center" >
                            <Grid item className="filter-view-data">
                                <Grid container>
                                    <Grid item xs={12}> <Typography className="alert-header"> Total Alerts </Typography> </Grid>
                                    <Grid item xs={12}> <Typography className="alert-count-data"> {totalAlertsCount} </Typography> </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className="filter-view-data">
                                <Grid container>
                                    <Grid item xs={12}> <Typography className="alert-header"> Channels Affected </Typography> </Grid>
                                    <Grid item xs={12}> <Typography className="alert-count-data"> {Object.keys(props.uniqueChannelAffected).length} </Typography> </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className="filter-view-data">
                                <Grid container>
                                    <Grid item xs={12}> <Typography className="alert-header"> Demand Tags Affected </Typography> </Grid>
                                    <Grid item xs={12}> <Typography className="alert-count-data"> {Object.keys(props.uniqueDemandTagAffected).length} </Typography> </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default FilterView