import React, { useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  Table,
  TableCell,
  TableHead, TableRow,
  TextField,
  Typography,
  TableBody, Button, Badge, Skeleton
} from "@mui/material";
import DetailsContent from "./DetailsContent";
import {filterData, isEmpty} from "../../../../utils/helper";
import {ASCENDING_ORDER, DELAY_SEARCH, DESCENDING_ORDER, INTERNAL_USER_AMG_ID} from "../../../../Constant";
import TableBodyComponent from "./TableBodyComponent";
import SortIcon from "../../../../icons/SortIcon";
import FilterDrawer from "../../../FilterDrawer";
import SearchIcon from "../../../../icons/SearchIcon";
import NoDataFound from "../../../../icons/NoData";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import useDebounce from "../../../../hooks/useDebounce";
import Loading from "../../../Loading";

function ListingContent(props) {
  const [alertData, setAlertData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedAlertData, setSelectedAlertData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filteredBadgeCount, setFilteredBadgeCount] = useState(0);
  const [sortedData, setSortedData] = useState({
    key: "epoch",
    order: DESCENDING_ORDER,
  })
  const [loadingData, setLoadingData] = useState(true)

  useEffect(() => {
    let countFiltered = 0
    if (props?.data && !isEmpty(props.data)) {
      setAlertData(props.data);
    }

    if(props?.defaultFilters?.alertName && !isEmpty(props.defaultFilters.alertName) ){
      countFiltered = countFiltered + 1
    }

    if(props?.defaultFilters?.channels && !isEmpty(props.defaultFilters.channels) ){
      countFiltered = countFiltered + props.defaultFilters.channels.length
    }

    setFilteredBadgeCount(countFiltered)
    setLoadingData(false)
  }, [props?.data, props.defaultFilters]);



  const onClickRow = (index) => {
    const getDetailsAlertData = alertData[index]

    setSelectedAlertData(getDetailsAlertData);
    setShowDrawer(true);
  };

  const onCloseDrawer = async () => {
    setShowDrawer(false);
    setSelectedAlertData({});
  };

  const onOpenCloseFilterDrawer = (value) => {
    setIsDrawerOpen(value);
  };

  const onSaveFilters = (filter) => {
    setIsDrawerOpen(false);
    props.onSideFilterChanges(filter)
  };

  const onSearchAlert = (e) => {
    const searchText = e?.target?.value
    setSearchText(searchText)
    performSearch(searchText)
  }

  const performSearch = useDebounce((searchText) => {
    const getMainPropsAlertData = Object.assign([], props.data);
    if (searchText !== "") {
      const updatedData = filterData(getMainPropsAlertData, searchText);
      setAlertData(updatedData);
    } else {
      setAlertData(props.data);
    }
  }, DELAY_SEARCH);

  const onClickSortData = (field) => {
    const getCurrentSortedState = Object.assign({}, sortedData)
    const getCurrentAlertedState = Object.assign([], alertData)
    let getUpdatedSortConfig = {"key": field, "order": ""}

    getUpdatedSortConfig.order = getCurrentSortedState.order === ASCENDING_ORDER ? DESCENDING_ORDER : ASCENDING_ORDER;
    if (getCurrentSortedState?.key && getCurrentSortedState.key !== field) {
      getUpdatedSortConfig.order = ASCENDING_ORDER;
    }

    getCurrentAlertedState.sort((a, b) => {
      if (getCurrentSortedState?.key && a[getCurrentSortedState.key] < b[getCurrentSortedState.key])
        return getCurrentSortedState.order === ASCENDING_ORDER ? -1 : 1;
      if (getCurrentSortedState?.key && a[getCurrentSortedState.key] > b[getCurrentSortedState.key])
        return getCurrentSortedState.order === DESCENDING_ORDER ? 1 : -1;
      return 0;
    });

    setSortedData(getUpdatedSortConfig)
    setAlertData(getCurrentAlertedState)
  }

  return (
    <Grid container className="listing-container">
      <Grid item xs={12} className="listing-filter-container">
        <div className="table-action-bar-top">
          <div>
            <Typography className="off-white-text">
              <span>{alertData.length} alerts</span>
            </Typography>
          </div>
          <div>
            <div className="generic-container-actionbar">
              <TextField
                value={searchText}
                onChange={(e) => onSearchAlert(e)}
                placeholder={`Search`}
                variant="outlined"
                size="small"
                className="generic-search-container"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon></SearchIcon>
                    </InputAdornment>
                  ),
                }}
              />
              <Button variant="outlined" onClick={() => onOpenCloseFilterDrawer(true)} className="black-outlined-button">
                <Badge badgeContent={filteredBadgeCount} color="primary">
                  <FilterAltIcon />
                </Badge>
              </Button>
              <FilterDrawer
                open={isDrawerOpen}
                onClose={() => onOpenCloseFilterDrawer(false)}
                onSaveFilters={onSaveFilters}
                uniqueAlertNameAffected = {props.uniqueAlertNameAffected}
                uniqueChannelAffected={props.uniqueChannelAffected}
                deafultFilters={props.defaultFilters}
                sideFiltersData={props.sideFiltersData}
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className="listing-table-container">
        <Table className="white-table">
          <TableHead>
            <TableCell width="11%">
              <div className="flex-align-content cursor-pointer user-select-none">
                <span>DATE / TIME</span>
                <span className="spacing-horizontal"></span>
                <SortIcon
                  className="sort-icon"
                  onClick={() => onClickSortData("epoch")}
                />
              </div>
            </TableCell>
            <TableCell width="5%">SEVERITY</TableCell>
            <TableCell width="11%">ALERT CATEGORY</TableCell>
            <TableCell width={props?.isInternal? "15%" : "12%" }>
              <div className="flex-align-content cursor-pointer user-select-none">
                <span>CHANNEL NAME</span>
                <span className="spacing-horizontal"></span>
                <SortIcon
                    className="sort-icon"
                    onClick={() => onClickSortData("channel_name")}
                />
              </div>
            </TableCell>
            <TableCell width="20%">
              <div className="flex-align-content cursor-pointer user-select-none">
                <span>ALERT NAME</span>
                <span className="spacing-horizontal"></span>
                <SortIcon
                    className="sort-icon"
                    onClick={() => onClickSortData("rule_name")}
                />
              </div>
            </TableCell>
            <TableCell width="10%">
              <div className="flex-align-content cursor-pointer user-select-none">
                <span>IMPACT ON</span>
                <span className="spacing-horizontal"></span>
              </div>
            </TableCell>

            {props?.isInternal ? (
                <TableCell width="18%">JIRA ID</TableCell> ) : (
                <TableCell width="28%">
                  <div className="flex-align-content cursor-pointer user-select-none">
                    <span>AD TAG ID</span>
                    <span className="spacing-horizontal"></span>
                  </div>
                </TableCell>
            )}
          </TableHead>
          {
              !loadingData && alertData.length > 0 &&
              (<TableBodyComponent alertData={alertData} onClickRow={onClickRow} isInternal={props?.isInternal}/>)
          }
          {
              !loadingData && alertData.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <NoDataFound />
                      </TableCell>
                    </TableRow>
                  </TableBody>
              )
          }
          {
            loadingData && alertData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Loading rowCount={3}/>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )
          }
        </Table>
      </Grid>
      <DetailsContent
        openDrawer={showDrawer}
        onCloseDrawer={onCloseDrawer}
        data={selectedAlertData}
        isInternal={props.isInternal}
      />
    </Grid>
  );
}

export default ListingContent;
