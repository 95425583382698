import Papa from "papaparse";

export const createCSVData = (data) => {
  // Define headers
  const headers = [
    "NAME",
    "EMAIL ID",
    "ROLE",
    "USER ADDED ON",
    "LAST LOGIN",
    "STATUS",
  ];

  const headerRow = headers.join(",") + "\n";
  const csv = data
    .map(
      (item) =>
        `${item.customer_id}, ${item.user_id}, ${item.user_role}, ${item.created_at}, ${item.updated_at}\n`
    )
    .join("");
  const csvData = headerRow + csv;
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  return url;
};

export const downloadCSV = (data, filename) => {
  const csvUrl = createCSVData(data);
  const a = document.createElement("a");
  a.href = csvUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const parseCSV = (file, callback) => {
  Papa.parse(file, {
    complete: (result) => {
      callback(result.data);
    },
    header: true,
    skipEmptyLines: true,
  });
};
