import { Menu, MenuItem } from "@mui/material";
import React from "react";

function DropdownMenu({ anchorEl, open, onClose, selectedValue, options, onValueChange }) {
  
  const renderMenuItems = () =>
    options.map((option) => (
      <MenuItem
        onClick={(e) => {
          onValueChange && onValueChange(option);
          onClose(option.value);
        }}
        key={option.value}
        className={
          selectedValue === option.value ? "selected-menu-item" : "menu-item"
        }
      >
        {option.label}
      </MenuItem>
    ));

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => onClose()}
      className="menu-container"
      MenuListProps={{
        className: "menu",
      }}
    >
      {renderMenuItems()}
    </Menu>
  );
}

export default DropdownMenu;
