export const USER_DATA_KEY = "user_data"
export const AMAGI_BRANDING_TEXT = "Amagi’s FAST Platform to easily manage content, distribution and monetization."
export const ADS_OBSERVABILITY = process.env.ADS_OBSERVABILITY || "https://obs-spot-bff.ads.amagi.tv/graphql"
export const SOMETHING_WENT_WRONG = "Error fetching data. Please try again."
export const JOB_RUN_MAPPING = {
  "daily": "Daily",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "hourly": "Hourly"
}
export const AUTH_GET_ACCESS_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN  || "login.iota.amagi.tv"
export const MAX_LENGTH = "15"
export const AUTH_GET_ACCESS_TOKEN = process.env.REACT_APP_AUTH0_DOMAIN || "login.iota.amagi.tv"
export const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || "qVqOoNnA33ladZX6umgItKGZXzLpPx04"
export const GRANT_TYPE = "authorization_code"
export const CURRENT_ORIGIN = process.env.REACT_APP_SERVER_URL || window.location.origin;
export const USER_NOT_VALID = "User is not valid."
export const ASCENDING_ORDER = "ASC"
export const DESCENDING_ORDER = "DESC"
export const REACT_APP_SENTRY_DSN = process.env.SENTRY_DSN || "https://994d93909aa7473197d8002809534499@o69923.ingest.sentry.io/4505594738049024"
export const AMAGI_LOGO = "https://storage.googleapis.com/ads-assets-ui/amagi_branding.png"
export const JIRA_URL_PREFIX = "https://amagiengg.atlassian.net/browse/"
export const RULES_DATA_LOCAL_KEY = "rules_data"
export const DEMAND_TAG_MAPPING_KEY = "demand_tag_mapping"
export const FILTER_CONFIG_KEY = "filter_config"
export const TIME_OPTIONS = [
  {
    label: "24 hours",
    value: "24hrs"
  },
  {
    label: "72 hours",
    value: "72hrs"
  },
  {
    label: "7 days",
    value: "7days"
  },
]
export const DEFAULT_FILTER_VALUE = {
    channels: [],
    alertName: "",
    epoch : 0
};

export const SIDE_FILTER_OPTIONS = [{"label": "Channels", "value": "channels"}, {"label": "Alert Name", "value": "alertName"}]
export const CHANNEL_SIDE_FILTER_VALUE = "channels"
export const ALERT_NAME_SIDE_FILTER_VALUE = "alertName"
export const DEFAULT_FILTER_CONFIG = {
  time: "72hrs",
  channels: [],
  alertName: ""
}
export const VAST_URL_REGEX = [
  /\/rt\/(\d+?)\?/,
  /\/vast\?supply_id=(\d+?)&/,
  /\/vast\/(\d+?)\?/,
  /\/vast\/(\d+?)\?/,
  /\/v1\/s2s-hb\?site_id=(\d+?)&/
]
export const MAX_DEMAND_TAGS_VIEW = 6
export const AMG_REGEX = /amg\d+|-/ig
export const FORMATED_COMMA_KEY = ["Viewership", "Bids", "ScteCount", "AdRequests", "AdImpressions"]
export const ISSUE_DETAIL_ORDER = ["Time", "AdRequests", "ScteCount", "Viewership", "AdImpressions", "Bids", "MetricRate"]
export const CHANGE_TIME_FORMAT = (old_format) => {
  const new_format = new Date(old_format).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
  return new_format;
}
export const ROLE_LABELS = {
  'rol_HM3zwrpqi0npPNAQ': 'Admin',
  'rol_wXO5CcdjvkeIa142': 'User',
};
export const INITIAL_BASE_URL = "/dashboard/"
export const MAX_LIMIT_SHOW_TABLE_DATA = 10
export const ALERT_ICONS = 'https://storage.googleapis.com/ads-assets-ui/Icon.png'
export const DISABLE_FEATURE_MSG = "Currently this feature disabled from system."
export const INTERNAL_USER_AMG_ID = 'AMG001'
export const AUTO_HIDE_DURATION_TIME = 6000
export const ADMIN_USER_ROLE = "observability_admin_operator"
export const SIDE_FILTERS_DATA_KEY = "side_filters"
export const API_CALL_ONGOING = "api_call"
export const CHANNEL_MAPPING_NOT_FOUND = "ChannelMappingNotFound"
export const NORMAL_USER_ROLE = "observability_normal_operator"
export const DELAY_SEARCH = 200
