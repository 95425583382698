import React, {useEffect} from "react"
import {Button, Grid, Typography} from "@mui/material";

function Error(props){

    useEffect(() => {
        console.log(props)
    }, [props])

    const onClickButton = () => {
        window.location.href = "/"
    }

    return(
        <Grid container className="error-div">
            <Grid item xs={12}>
                <Typography className="white-text" variant="h2"> 503 </Typography>
            </Grid>
            <Grid item xs={12} className="error-description-div">
                <Typography className="white-text" variant="h6">Something went wrong</Typography>
            </Grid>
            <Grid item xs={12} className="error-button-div">
                <Button variant="contained" className="filled-submit-button" onClick={() => onClickButton()}> Go Home </Button>
            </Grid>
        </Grid>
    )
}

export default Error