import SettingsIcon from '@mui/icons-material/Settings';
import { Avatar, Grid, } from "@mui/material";
import { Divider } from '@mui/material';
import { Button } from "@mui/material";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, {useState} from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import amagi_branding_logo from "../../../assests/images/amagi_branding.png";
import {ADMIN_USER_ROLE, INITIAL_BASE_URL, USER_DATA_KEY} from "../../../Constant";
import { removeLocalStorageData } from "../../../utils/localStorageHelper";
import CustomizedDialogs from "./UserDetailPopover";

function Header(props) {
  const [adminUser, setAdminUser] = useState(false)
  const [userData, setUserData] = useState({})
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  useEffect(() => {
    if(props?.userData?.user_roles?.includes(ADMIN_USER_ROLE)){
      setAdminUser(true)
    }

    setUserData(props?.userData)
  }, [props?.userData?.user_roles]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = () => {
    removeLocalStorageData(USER_DATA_KEY, true);
    window.location.href = "/";
  };

  const goToUserManagement = () => {
    navigate("/user-management");
    handleClose();
  };

  return (
    <Grid container className="amagi-header">
      <Grid item xs={2}>
        <img
          src={amagi_branding_logo}
          alt="amagi"
          className="amagi-header-logo"
          onClick={() => { navigate(INITIAL_BASE_URL) }}
        />
      </Grid>
      <Grid item>
        <div style={{ display: "flex" }}>

          {adminUser && (
            <SettingsIcon className="user-management-icon" onClick={goToUserManagement} />
          )}

          <Avatar
            className="white-icon"
            onClick={(e) => handleClick(e)}
            id="user-icon"
          />

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            className="user-detail-popover"
          >
            <Avatar
              className="user-avatar-dropdown"
              onClick={(e) => handleClick(e)}
              id="user-icon"
            />
            <Typography className="user-name-typography">{userData?.name}</Typography>
            <Typography className="user-role-typography"> {adminUser ? "Admin": "User"} </Typography>
            <Typography className="user-email-typography">{userData?.email}</Typography>
            <CustomizedDialogs userData={userData} adminUser={adminUser} />
            <Divider className="user-profile-divider" />
            <Button variant="contained" onClick={() => onClickLogout()} className="logout-btn">Log Out</Button>
          </Popover>
        </div>
      </Grid>
    </Grid>
  );
}

export default Header;
