import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material";
import FilterView from "./FilterView";
import ListingContent from "./ListingContent";
import Loading from "../../Loading"
import {
    calculateTimeFromNow,
    getCurrentTime,
    getLastEpoch,
    getUniqueChannlesAndRules,
    isEmpty
} from "../../../utils/helper";
import TopActionBar from "./TopActionBar";
import NoData from "../../../icons/NoData";
import {mapperData} from "../../../utils/mapperData";
import Notification from "../../Notification";
import {
    API_CALL_ONGOING,
    DEFAULT_FILTER_CONFIG, DEFAULT_FILTER_VALUE,
    DEMAND_TAG_MAPPING_KEY, FILTER_CONFIG_KEY, INTERNAL_USER_AMG_ID,
    RULES_DATA_LOCAL_KEY, SIDE_FILTERS_DATA_KEY,
    SOMETHING_WENT_WRONG,
} from "../../../Constant";
import {getLocalStorageData, setLocalStorageData} from "../../../utils/localStorageHelper"
import {getRulesData} from "../../../api/getRulesData";
import {getFiltersData} from "../../../api/getFiltersData";
import {getSupplyDemandTagData} from "../../../api/getSupplyDemandTagData";

function Body(props) {
    const [alertsData, setAlertsData] = useState([])
    const [loadingData, setLoading] = useState(true)
    const [filterConfig, setFilterConfig] = useState(DEFAULT_FILTER_CONFIG)
    const [showNotification, setShowNotification] = useState(false)
    const [isInternal, setIsInternal] = useState(false);
    const [uniqueDemandTagAffected, setUniqueDemandTagAffected] = useState();
    const [uniqueChannelAffected, setUniqueChannelAffected] = useState();
    const [uniqueAlertNameAffected, setUniqueAlertNameAffected] = useState({})
    const [sideFiltersData, setSideFiltersData] = useState({})
    const [userAmgId, setUserAmgId] = useState("")

    useEffect(() => {
        loadData()
    }, [props?.userData])

    const loadData = async () => {
        setLoading(true);
        const getLocalApiCallOngoing = getLocalStorageData(API_CALL_ONGOING, false)
        if(getLocalApiCallOngoing === "false"){
            setLocalStorageData(API_CALL_ONGOING, false, true)
            const getAlertsLocalData = getLocalStorageData(RULES_DATA_LOCAL_KEY, true)
            const getDemandTagLocalData = getLocalStorageData(DEMAND_TAG_MAPPING_KEY, true)
            let getFilterConfigData = getLocalStorageData(FILTER_CONFIG_KEY, true)
            let getSideFiltersLocalData = getLocalStorageData(SIDE_FILTERS_DATA_KEY, true)
            const getCurrentEpochTime = getCurrentTime()
            let rulesDemandTagMappingData = []
            let uniqueChannels = {}
            let uniqueDemandPartner = {}
            let uniqueAlertsName = {}
            let getFilterValue = DEFAULT_FILTER_VALUE
            let isUserInternal = false
            let userAmgId = props.userData?.organization_metadata?.amg_id || INTERNAL_USER_AMG_ID
            let getSideFiltersData = {}

            if(userAmgId === INTERNAL_USER_AMG_ID){
                isUserInternal = true
            }

            if(getAlertsLocalData?.data?.alertMappedData?.length > 0 && getDemandTagLocalData?.data?.length > 0 && getAlertsLocalData?.ttl > getCurrentEpochTime && getDemandTagLocalData?.ttl > getCurrentEpochTime && getFilterConfigData && Object.keys(getSideFiltersLocalData?.data).length > 0){
                rulesDemandTagMappingData = getAlertsLocalData.data.alertMappedData
                uniqueChannels = getAlertsLocalData.data.uniqueChannelsAffected
                uniqueDemandPartner = getAlertsLocalData.data.uniqueDemandTagAffected
                uniqueAlertsName = getAlertsLocalData.data.uniqueAlertNameAffected
                getSideFiltersData = getSideFiltersLocalData.data
            }else {
                const lastEpoch = isEmpty(getFilterConfigData) ? getLastEpoch(DEFAULT_FILTER_CONFIG.time) : getLastEpoch(getFilterConfigData.time)
                getFilterValue.epoch = lastEpoch
                if(getFilterConfigData){
                    getFilterConfigData["alertName"] =  ""
                    getFilterConfigData["channels"] =   []
                }else {
                    getFilterConfigData = {"alertName": "", "channels": [], "time": "72hrs"}
                }

                const getAlertsResponse = await getRulesData(userAmgId, getFilterValue)
                const getDemandMappingResponse = await getSupplyDemandTagData(userAmgId)
                const getSideFilterResponse = await getFiltersData(userAmgId)
                const futureEpochTime = calculateTimeFromNow(3600);


                if(getDemandMappingResponse?.getSupplyDemandTagMapping?.length > 0){
                    setLocalStorageData(DEMAND_TAG_MAPPING_KEY, true , {"data": getDemandMappingResponse?.getSupplyDemandTagMapping, "ttl": futureEpochTime})
                }else {
                    setLocalStorageData(DEMAND_TAG_MAPPING_KEY, true , {"data": [], "ttl": futureEpochTime})
                }

                if(getAlertsResponse?.getRulesData?.length > 0 && getDemandMappingResponse?.getSupplyDemandTagMapping?.length > 0){
                    const getMappingData = Object.assign({}, mapperData(getAlertsResponse?.getRulesData, getDemandMappingResponse?.getSupplyDemandTagMapping))
                    rulesDemandTagMappingData = getMappingData?.alertMappedData
                    uniqueChannels = getMappingData.uniqueChannelsAffected
                    uniqueDemandPartner = getMappingData.uniqueDemandTagAffected
                    uniqueAlertsName = getMappingData.uniqueAlertNameAffected

                    setLocalStorageData(RULES_DATA_LOCAL_KEY, true , {"data": getMappingData, "ttl": futureEpochTime})
                }else {
                    setLocalStorageData(RULES_DATA_LOCAL_KEY, true , {"data": [], "ttl": futureEpochTime})
                }

                if(isEmpty(getFilterConfigData)){
                    getFilterConfigData = DEFAULT_FILTER_CONFIG
                    setLocalStorageData(FILTER_CONFIG_KEY, true , getFilterConfigData)
                }else {
                    setLocalStorageData(FILTER_CONFIG_KEY, true , getFilterConfigData)
                }

                if(!getAlertsResponse?.getRulesData && !getDemandMappingResponse?.getSupplyDemandTagMapping){
                    setShowNotification(true)
                }

                if(getSideFilterResponse?.getAlertsFilter?.length > 0){
                    getSideFiltersData = getUniqueChannlesAndRules(getSideFilterResponse.getAlertsFilter)
                    setLocalStorageData(SIDE_FILTERS_DATA_KEY, true , {"data": getSideFiltersData, "ttl": futureEpochTime})
                }else {
                    setLocalStorageData(SIDE_FILTERS_DATA_KEY, true , {"data": [], "ttl": futureEpochTime})
                }
            }

            setAlertsData(rulesDemandTagMappingData)
            setFilterConfig(getFilterConfigData)
            setUniqueDemandTagAffected(uniqueDemandPartner)
            setUniqueChannelAffected(uniqueChannels)
            setUniqueAlertNameAffected(uniqueAlertsName)
            setIsInternal(isUserInternal)
            setSideFiltersData(getSideFiltersData)
            setUserAmgId(userAmgId)
            setLoading(false);
        }
        setLocalStorageData(API_CALL_ONGOING, false, false)
    }
    const onTimeFilterChange = async (time = "72hrs") => {
        setLoading(true);
        const lastEpoch = getLastEpoch(time)
        let getFilterValue = DEFAULT_FILTER_VALUE
        getFilterValue.epoch = lastEpoch
        const getAlertsResponse = await getRulesData(userAmgId,getFilterValue)
        const getSideFilterResponse = await getFiltersData(userAmgId)
        const getDemandTagLocalData = getLocalStorageData(DEMAND_TAG_MAPPING_KEY, true)
        const getCurrentStateFilter = Object.assign({}, filterConfig)
        getCurrentStateFilter.time = time
        const futureEpochTime = calculateTimeFromNow(3600);
        let rulesDemandTagMappingData = []
        let uniqueChannels = {}
        let uniqueDemandPartner = {}
        let getSideFiltersData = {}

        if(getAlertsResponse?.getRulesData?.length > 0 && getDemandTagLocalData?.data.length > 0){
            const getMappingData = Object.assign({}, mapperData(getAlertsResponse?.getRulesData, getDemandTagLocalData?.data))
            rulesDemandTagMappingData = getMappingData?.alertMappedData
            uniqueChannels = getMappingData.uniqueChannelsAffected
            uniqueDemandPartner = getMappingData.uniqueDemandTagAffected
            setLocalStorageData(RULES_DATA_LOCAL_KEY, true , {"data": getMappingData, "ttl": futureEpochTime})
            setLocalStorageData(FILTER_CONFIG_KEY, true , getCurrentStateFilter)
        }else if(getAlertsResponse?.getRulesData === null){
            setLocalStorageData(FILTER_CONFIG_KEY, true , getCurrentStateFilter)
            setLocalStorageData(RULES_DATA_LOCAL_KEY, true , {"data": [], "ttl": futureEpochTime})
        }

        if(getSideFilterResponse?.getAlertsFilter?.length > 0){
            getSideFiltersData = getUniqueChannlesAndRules(getSideFilterResponse.getAlertsFilter)
            setLocalStorageData(SIDE_FILTERS_DATA_KEY, true , {"data": getSideFiltersData, "ttl": futureEpochTime})
        }else {
            setLocalStorageData(SIDE_FILTERS_DATA_KEY, true , {"data": [], "ttl": futureEpochTime})
        }

        setAlertsData(rulesDemandTagMappingData)
        setFilterConfig(getCurrentStateFilter)
        setUniqueDemandTagAffected(uniqueDemandPartner)
        setUniqueChannelAffected(uniqueChannels)
        setSideFiltersData(getSideFiltersData)
        setLoading(false);
    }
    const onSideFilterChanges = async (filterData) => {
        setLoading(true);
        const getCurrentStateFilter = Object.assign({}, filterConfig)
        const lastEpoch = getLastEpoch(getCurrentStateFilter?.time)
        const futureEpochTime = calculateTimeFromNow(3600);
        const getDemandTagLocalData = getLocalStorageData(DEMAND_TAG_MAPPING_KEY, true)
        let rulesDemandTagMappingData = []
        let uniqueChannels = {}
        let uniqueDemandPartner = {}
        let getAlertsResponse = {}
        let getFilterValue = DEFAULT_FILTER_VALUE
        getFilterValue.epoch = lastEpoch
        let getSideFiltersData = {}

        if(filterData?.alertName && !isEmpty(filterData.alertName)){
            getCurrentStateFilter.alertName = filterData.alertName
            getFilterValue.alertName = filterData.alertName
        }else {
            getCurrentStateFilter.alertName = ""
            getFilterValue.alertName = ""
        }

        if(filterData?.channels && !isEmpty(filterData.channels)){
            getCurrentStateFilter.channels = filterData.channels
            getFilterValue.channels = filterData.channels
        }else {
            getCurrentStateFilter.channels = []
            getFilterValue.channels = []
        }

        getAlertsResponse = await getRulesData(userAmgId, getFilterValue)
        const getSideFilterResponse = await getFiltersData(userAmgId)

        if(getAlertsResponse?.getRulesData?.length > 0 && getDemandTagLocalData?.data.length > 0){
            const getMappingData = Object.assign({}, mapperData(getAlertsResponse?.getRulesData, getDemandTagLocalData?.data))
            rulesDemandTagMappingData = getMappingData?.alertMappedData
            uniqueChannels = getMappingData.uniqueChannelsAffected
            uniqueDemandPartner = getMappingData.uniqueDemandTagAffected
            setLocalStorageData(RULES_DATA_LOCAL_KEY, true , {"data": getMappingData, "ttl": futureEpochTime})
            setLocalStorageData(FILTER_CONFIG_KEY, true , getCurrentStateFilter)
        }else if(getAlertsResponse?.getRulesData === null){
            setLocalStorageData(FILTER_CONFIG_KEY, true , getCurrentStateFilter)
            setLocalStorageData(RULES_DATA_LOCAL_KEY, true , {"data": [], "ttl": futureEpochTime})
        }

        if(getSideFilterResponse?.getAlertsFilter?.length > 0){
            getSideFiltersData = getUniqueChannlesAndRules(getSideFilterResponse.getAlertsFilter)
            setLocalStorageData(SIDE_FILTERS_DATA_KEY, true , {"data": getSideFiltersData, "ttl": futureEpochTime})
        }else {
            setLocalStorageData(SIDE_FILTERS_DATA_KEY, true , {"data": [], "ttl": futureEpochTime})
        }

        setAlertsData(rulesDemandTagMappingData)
        setFilterConfig(getCurrentStateFilter)
        setUniqueDemandTagAffected(uniqueDemandPartner)
        setUniqueChannelAffected(uniqueChannels)
        setSideFiltersData(getSideFiltersData)
        setLoading(false);
    }

    const onCloseNotification = () => {
        setShowNotification(false)
    }

    return (
        <Grid conatiner className="amagi-body-div">
            <Grid item xs={12}>
                <TopActionBar
                    onTimeFilterChange={onTimeFilterChange}
                    filterConfig={filterConfig}
                />
            </Grid>
            {
                loadingData && <Loading rowCount={4}/>
            }
            {
                !loadingData && alertsData?.length > 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            <FilterView
                                data={alertsData}
                                uniqueDemandTagAffected={uniqueDemandTagAffected}
                                uniqueChannelAffected={uniqueChannelAffected}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ListingContent
                                data={alertsData}
                                isInternal={isInternal}
                                uniqueAlertNameAffected={uniqueAlertNameAffected}
                                uniqueChannelAffected={uniqueChannelAffected}
                                onSideFilterChanges={onSideFilterChanges}
                                defaultFilters={filterConfig}
                                sideFiltersData={sideFiltersData}
                            />
                        </Grid>
                    </Grid>
                )

            }
            {
                !loadingData && alertsData.length === 0 && (
                    <NoData />
                )
            }
            {
                showNotification && <Notification open={showNotification} handleClose={onCloseNotification} message={SOMETHING_WENT_WRONG}/>
            }
        </Grid>
    )
}

export default Body