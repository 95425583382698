import React, {useEffect} from "react"

function FallBackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    useEffect(() => {
        if(error){
        }
    }, [error])

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
    );
}

export default FallBackRender