"use client";
import { Box } from "@mui/material";
import Content from "./Component/Content";
import UserBody from "./Component/UserManagement";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Component/Login";
import "./assests/css/theme.css";
import Error from "./Component/Error";
import { REACT_APP_SENTRY_DSN } from "./Constant";
import Health from "./Component/Health";
import * as Sentry from "@sentry/react";
import { AccessProvider } from "./contexts/AccessContext";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "./apollo/client";

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  release: "0.0.1",
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <Error />,
    hasErrorBoundary: false,
  },
  {
    path: "/dashboard",
    element: <Content />,
    errorElement: <Error />,
    hasErrorBoundary: false,
  },
  {
    path: "/user-management",
    element: <UserBody />,
    errorElement: <Error />,
    hasErrorBoundary: false,
  },
  {
    path: "/health",
    element: <Health />,
    errorElement: <Error />,
    hasErrorBoundary: false,
  },
]);
const client = createApolloClient();

function App(props) {
  return (
    <Sentry.ErrorBoundary fallback={Error}>
      <ApolloProvider client={client}>
        <AccessProvider>
          <Box className="main-component">
            <RouterProvider router={router} />
          </Box>
        </AccessProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;