import React, {useEffect, useState} from "react";
import {Checkbox, InputAdornment, TextField} from "@mui/material";
import {isEmpty} from "../../utils/helper";
import SearchIcon from "../../icons/SearchIcon";

function ChannelContent(props) {
    const [_channelNames, _setChannelNames] = useState([]);
    const [resetMode, setResetMode] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const handleResetClick = () => {
        if (resetMode) {
            props.handleOnChecked("");
        } else {
            props.handleOnChecked(_channelNames );
        }
        setResetMode((prev) => !prev);
    };

    useEffect(() => {
        if (_channelNames.length === props.selectedChannels?.length) {
            setResetMode(true);
        } else {
            setResetMode(false);
        }
    }, [props.selectedChannels?.length, _channelNames.length]);

    useEffect(() => {
        _setChannelNames(
            props.channelNames?.filter((name) => {
                return name?.toLowerCase().startsWith(searchValue);
            })
        );
    }, [props?.channelNames, searchValue]);

    const onCheckedItem = (channel) => {
        const newChannels = props.selectedChannels
        if (newChannels.includes(channel)) {
            const index = newChannels.indexOf(channel);
            newChannels.splice(index, 1);
        } else {
            newChannels.push(channel);
        }
        props.handleOnChecked(newChannels);
    }

    return (
        <React.Fragment>
            <TextField
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search Channels"
                variant="outlined"
                size="small"
                className="generic-search-container tabPanelContent"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon></SearchIcon>
                        </InputAdornment>
                    ),
                }}
            />
            <div className="tabPanelContent">
                <div className="filter-list-container">
                    {_channelNames.map((channel) => (
                        <div key={channel} className="filter-list-options">
                            <Checkbox
                                checked={props.selectedChannels?.includes(channel)}
                                onChange={() => onCheckedItem(channel)}
                                className="checkbox"
                            />
                            <span
                                className={
                                    props.selectedChannels?.includes(channel)
                                        ? "filter-list-option-label-selected"
                                        : "filter-list-option-label"
                                }
                            >
                            {channel?.replace(/_/g, " ")?.split('|')[1]?.trim() || channel?.replace(/_/g, " ")}
                        </span>
                        </div>
                    ))}
                </div>
                {isEmpty(props.searchValue) && (
                    <div className="reset-button" onClick={handleResetClick}>
                        {resetMode ? "Reset All" : "Select All"}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default ChannelContent