import axios from "axios";
import {ADS_OBSERVABILITY, INTERNAL_USER_AMG_ID} from "../Constant";
import {GetSupplyDemandTagsMappingQuery} from "../query/getSupplyDemandTagsMapping";


export const getSupplyDemandTagData = (amg_id = INTERNAL_USER_AMG_ID) => {
    let data = JSON.stringify({
        query: GetSupplyDemandTagsMappingQuery,
        variables: {"id": amg_id }
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: ADS_OBSERVABILITY,
        headers: {
            'amg_id': 'AMG001',
            'Content-Type': 'application/json'
        },
        data : data
    };

    return axios.request(config)
        .then((response) => {
            if(response?.data?.data && response.status === 200){
                return response.data.data
            }else {
                return []
            }
        })
        .catch((error) => {
            return error
        });
}