import CloseIcon from '@mui/icons-material/Close';
import { Avatar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React,{useState} from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Typography className="user-view-profile-typography" onClick={handleClickOpen}>View profile</Typography>
            <BootstrapDialog
                className='user-profile-dialog'
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className='user-profile-dialog-title'>
                    My Profile
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className='user-profile-dialog-content'>
                    <Typography gutterBottom>
                        <Avatar
                            // alt={props.userData?.name}
                            // src={props.userData?.picture}
                            className="user-avatar-dropdown"
                            id="user-icon"

                        />
                    </Typography>
                    <Typography gutterBottom>
                        <div className='profile-feature'><Typography className='profile-feature-typography'>Name</Typography></div>
                        <div className='profile-feature'><Typography className='profile-feature-typography'>Role</Typography></div>
                        <div className='profile-feature'><Typography className='profile-feature-typography'>Email</Typography></div>
                        <div className='profile-feature'><Typography className='profile-feature-typography'>Password</Typography></div>
                    </Typography>
                    <Typography gutterBottom>
                        <div className='profile-feature'><Typography className='profile-feature-value-typography'>{props.userData?.name}</Typography></div>
                        <div className='profile-feature'><Typography className='profile-feature-value-typography'>  {props.adminUser ? "Admin" : "User"}</Typography></div>
                        <div className='profile-feature'><Typography className='profile-feature-value-typography'>{props.userData?.email}</Typography></div>
                        <div className='profile-feature'><Typography className='profile-feature-value-typography'>*************</Typography></div>
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
}
