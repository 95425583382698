import { useRef, useEffect } from "react";

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  const debounceCallback = (...args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return debounceCallback;
};

export default useDebounce;
