import React from "react"
import {Snackbar, Alert} from "@mui/material";

function Notification(props){
    return(
        <Snackbar open={props.open} autoHideDuration={6000} onClose={props.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={props.handleClose} severity="error" sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

export default Notification