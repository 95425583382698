const SortIcon = ({onClick}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onClick={() => onClick()}
    >
      <path
        d="M6.89659 2.00003H5.13851L5.13851 10.6707H3.4402C3.35661 10.6706 3.27472 10.6881 3.20412 10.7212C3.13353 10.7543 3.07716 10.8016 3.04164 10.8576C3.00611 10.9136 2.9929 10.9759 3.00355 11.0372C3.0142 11.0986 3.04828 11.1564 3.10177 11.2039L6.11952 13.8831C6.1774 13.9345 6.25526 13.9714 6.34251 13.9888C6.42975 14.0062 6.52214 14.0033 6.60708 13.9805C6.69203 13.9577 6.7654 13.916 6.8172 13.8613C6.86901 13.8065 6.89673 13.7412 6.89659 13.6744L6.89659 2.00003Z"
        fill="#868686"
      />
      <path
        d="M9.10335 14H10.8614L10.8614 5.32941H12.5597C12.6433 5.32951 12.7252 5.31197 12.7958 5.27886C12.8664 5.24574 12.9228 5.19842 12.9583 5.14245C12.9938 5.08647 13.007 5.02416 12.9964 4.96283C12.9857 4.90149 12.9517 4.84368 12.8982 4.79616L9.88042 2.11694C9.82254 2.0656 9.74468 2.0287 9.65743 2.01127C9.57019 1.99385 9.4778 1.99674 9.39285 2.01956C9.30791 2.04238 9.23454 2.08401 9.18273 2.13879C9.13093 2.19357 9.10321 2.25883 9.10335 2.32569L9.10335 14Z"
        fill="#868686"
      />
    </svg>
  );
};

export default SortIcon
