import { Alert, Snackbar } from "@mui/material";
import React from "react";

function UserNotification(props) {
  return (
    <Snackbar
      className="user-del-snack"
      open={props.open}
      autoHideDuration={3000}
      onClose={props.handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={props.handleClose}
        severity="success"
        sx={{ width: "100%" }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export default UserNotification;
