const NoDataFound = () => {
    return (
        <div className="no-data-found-container">
            <div>
                <NoDataFoundIcon></NoDataFoundIcon>
            </div>
            <h3 className="no-data-found-container-header">No alerts available</h3>
            <div>
                <span className="no-data-found-container-subtext">Please refine yout search and try again.</span>
            </div>
        </div>
    );
};

const NoDataFoundIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
        >
            <path
                d="M61.9533 66.6667H13.3333C13.0238 66.6667 12.7204 66.5805 12.4571 66.4178C12.1938 66.255 11.981 66.0222 11.8426 65.7454C11.7042 65.4685 11.6456 65.1586 11.6734 64.8503C11.7012 64.5421 11.8143 64.2476 12 64L13.3333 62.2233V33.3333C13.3333 28.9 14.4133 24.72 16.33 21.0433L4.64331 9.36001L9.35998 4.64334L75.3566 70.6433L70.64 75.3567L61.9533 66.6667ZM21.36 26.0733C20.4587 28.3876 19.9975 30.8498 20 33.3333V60H55.2866L21.36 26.0733ZM66.6666 52.62L60 45.9533V33.3333C60.0006 29.8256 59.0787 26.3793 57.3266 23.3404C55.5746 20.3015 53.0542 17.7768 50.0182 16.0197C46.9822 14.2627 43.5375 13.335 40.0297 13.3297C36.522 13.3245 33.0745 14.242 30.0333 15.99L25.2 11.15C29.2158 8.47073 33.8835 6.93183 38.7054 6.69746C43.5272 6.46309 48.3222 7.54206 52.5789 9.81925C56.8356 12.0964 60.3943 15.4864 62.8753 19.6276C65.3564 23.7688 66.6668 28.5058 66.6666 33.3333V52.62ZM31.6666 70H48.3333C48.3333 72.2102 47.4553 74.3298 45.8925 75.8926C44.3297 77.4554 42.2101 78.3333 40 78.3333C37.7898 78.3333 35.6702 77.4554 34.1074 75.8926C32.5446 74.3298 31.6666 72.2102 31.6666 70Z"
                fill="#868686"
            />
        </svg>
    );
};

export default NoDataFound