import { gql, useMutation } from '@apollo/client';

const DELETE_USER = gql`
mutation MyMutation($updateUserRoleData: AuthChangeUserRole = {}) {
  updateUserRoleControlPlane(updateUserRoleData: $updateUserRoleData)
}
`;

export const UpdateUserRole = () => {
    const [updateUserRoleControlPlane, { loading, error }] = useMutation(DELETE_USER);

    const handleUpdateRole = async (updateUserRoleData) => {
        try {
            const response = await updateUserRoleControlPlane({
                variables: {
                    ...updateUserRoleData
                },
            });
            return true;
        } catch (mutationError) {
            console.error('Error deleting user:', mutationError);
            return false;
        }
    };

    return { handleUpdateRole, loading, error };
};