import React, { createContext, useContext, useState } from "react";

const DeleteConfirmContext = createContext();

export const useDeleteConfirm = () => {
  return useContext(DeleteConfirmContext);
};

export const DeleteConfirmProvider = ({ children }) => {
  const [openDeletePop, setOpenDeletePop] = useState(false);
  const [heading, setHeading] = useState();
  const [message, setMessage] = useState();
  const [btnName, setBtnName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userRole, setUserRole] = useState();

  const handleClickOpenDelete = () => {
    setOpenDeletePop(true);
    console.log(openDeletePop);
  };

  const handleClickClose = () => setOpenDeletePop(false);

  return (
    <DeleteConfirmContext.Provider
      value={{
        openDeletePop,
        handleClickClose,
        handleClickOpenDelete,
        setHeading,
        heading,
        message,
        setMessage,
        btnName,
        setBtnName,
        userEmail,
        setUserEmail,
        userRole,
        setUserRole
      }}
    >
      {children}
    </DeleteConfirmContext.Provider>
  );
};
