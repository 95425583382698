import React from "react";

import DropdownButton from './DropdownButton';
import DropdownMenu from './DropdownMenu';

function GenericDropdown({ anchorEl, open, onClose, selectedOption, options, icon, onValueChange, setOpen }) {
  return (
    <React.Fragment>
      <DropdownButton 
        onClick={() => setOpen(!open)} 
        selectedLabel={selectedOption}
        icon={icon}
      />
      <DropdownMenu 
        anchorEl={anchorEl} 
        open={open}
        onClose={onClose}
        selectedValue={selectedOption}
        options={options}
        onValueChange={onValueChange}
      />
    </React.Fragment>
  );
}

export default GenericDropdown;
