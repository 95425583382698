import { gql, useQuery } from "@apollo/client";

const GET_USERS_DATA = gql`
  query {
    getAllUsers {
      customer_id
      user_id
      user_role
      created_at
      updated_at
    }
  }
`;

export const useGetUsersData = () => {
    const { loading, error, data, refetch } = useQuery(GET_USERS_DATA, {
        context: {
            headers: {
                identifier: 'getAllUsers',
            },
        },
    });

    return { usersLoading: loading, usersError: error, usersData: data, usersRefetch: refetch };
};
